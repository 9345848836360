import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import dateFormat, { masks } from "dateformat";
import { NumericFormat } from 'react-number-format';
import callFetch from "../../../helpers/callFetch";
import CorrectionRequestView from "./CorrectionRequestView";

import SoftTypography from "components/SoftTypography";

function Timeline(props) {
    const { t } = useTranslation();
    let params = useParams();
    
    const [refresh, setRefresh] = useState(1);
    const [data, setData] = useState([]);
    var AppointmentSerial = 1;
    var QuotationSerial = 1;
    var Orderserial = 1;
    var serial = 1;


    useEffect(() => {
        if(!params.id) return;

        callFetch("project/timelines/"+(props?.projectid ? props?.projectid : params.id), "GET", []).then((res) => {
            setData(res.data);
        });
    }, [props?.projectid+refresh+params?.id+(props?.refresh ?? 0)]);
    
    return(
        <>
            {data && data.project ? (
                <div className="card">
                    <div className="card-body">
                        <h6>{t('Project Status')}</h6>
                        <div className="timeline timeline-one-side" data-timeline-axis-style="dotted">
                            <div className="timeline-block mb-3">
                                <span className="timeline-step">
                                    <i className="ni ni-bell-55 text-success text-gradient" />
                                </span>
                                <div className="timeline-content">
                                    <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Appointment')}</h6>
                                    <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{dateFormat(data.project.date, "dd.mm.yyyy")}</p>

                                    <p className="text-sm mt-3 mb-2">{t('Client Information')}:</p>
                                    <p className="text-sm m-0">
                                        <SoftTypography variant="caption"><strong>{t('Name')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? data.project.vorname+' '+data.project.name : ''}</SoftTypography>
                                    </p>
                                    <p className="text-sm m-0">
                                        <SoftTypography variant="caption"><strong>{t('Straße')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? data.project.street : ''}</SoftTypography>
                                    </p>
                                    <p className="text-sm m-0">
                                        <SoftTypography variant="caption"><strong>{t('PLZ und Ort')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? data.project.plz+' '+data.project.ort : ''}</SoftTypography>
                                    </p>
                                    <p className="text-sm m-0 mb-1">
                                        <SoftTypography variant="caption"><strong>{t('Phone')}: </strong></SoftTypography> <SoftTypography variant="caption">{data.project && data.project != undefined ? data.project.telefonnummer : ''}</SoftTypography>
                                    </p>
                                    
                                    {data.project.corrections && data.project.corrections.map((correction, index) => {
                                        return <>
                                            {correction.related == 'Appointment' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={AppointmentSerial++}></CorrectionRequestView> : ''}
                                        </>                                        
                                    })}
                                </div>
                            </div>

                            {data.quations && data.quations.map((quation) => (
                                <>
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Quotation')}</h6>
                                        <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(quation.created_at, "dd.mm.yyyy")}</p>

                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Quotation Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{quation.id}</SoftTypography>
                                        </p>

                                        {Cookies.get('permissions').indexOf("quotation-read") !== -1 ?
                                            <p className="text-sm m-0 mb-1">
                                                <SoftTypography variant="caption"><strong>{t('Price')} Netto: </strong></SoftTypography>
                                                <SoftTypography variant="caption">
                                                    <NumericFormat value={quation.gesami_netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€
                                                </SoftTypography>
                                        </p>
                                        : ''}
                                        
                                        {data.project.corrections && data.project.corrections.map((correction) => (
                                            <>
                                                {correction.related == 'Quotation' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={QuotationSerial++}></CorrectionRequestView> : ''}
                                            </>                                        
                                        ))}
                                    </div>
                                </div>                                
                                </>
                            ))}

                            {data.orders && data.orders.map((order) => (
                                <>
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Order')}</h6>
                                        <p className="text-secondary font-weight-bold text-xs mt-1">{dateFormat(order.created_at, "dd.mm.yyyy")}</p>


                                        <p className="text-sm m-0">
                                            <SoftTypography variant="caption"><strong>{t('Order Nr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{order.id}</SoftTypography>
                                        </p>
                                        {Cookies.get('permissions').indexOf("quotation-read") !== -1 ?
                                            <p className="text-sm m-0 mb-1">
                                                <SoftTypography variant="caption"><strong>{t('Price')} Netto: </strong></SoftTypography> <SoftTypography variant="caption"><NumericFormat value={order.netto} displayType="text" thousandSeparator={"."} decimalSeparator="," decimalScale={2} />€</SoftTypography>
                                            </p>
                                        : ''}                                    
                                        
                                        {data.project.corrections && data.project.corrections.map((correction) => (
                                            <>
                                                {correction.related == 'Order' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={serial++}></CorrectionRequestView> : ''}
                                            </>                                        
                                        ))}                                    
                                    </div>
                                </div>
                                </>
                            ))}
                            
                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                {assign_order.dachmontag_elektromontage == 'Dachmontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{assign_order.time}</p>



                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Employee')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order.employee.name}</SoftTypography>
                                                </p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? 'Successfull' : "Not Successfull"}</SoftTypography>
                                                </p>
                                                <p className="text-sm mb-1">
                                                    <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.dcabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={process.env.REACT_APP_BACKEND_URL+'dcabname/' + assign_order?.dcabnameprotokoll?.id}>DC Abnahmeprotokoll PDF</a> : "--"}</SoftTypography>
                                                </p>

                                                {data.project.corrections && data.project.corrections.map((correction) => (
                                                    <>
                                                        {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                                                    </>                                        
                                                ))} 
                                            </div>
                                        </div>
                                    ) : ''
                                }                            
                                </>
                            ))}


                            {data.invoices && data.invoices.map((invoice, invoice_serial) => (
                                <>
                                <div className="timeline-block mb-3">
                                    <span className="timeline-step">
                                        <i className="ni ni-bell-55 text-success text-gradient" />
                                    </span>
                                    <div className="timeline-content">
                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{t('Invoice')+' '+JSON.parse(invoice?.product_details)?.amountPerchantage+'%'}</h6>
                                        <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(invoice?.created_at, "dd.mm.yyyy")}</p>

                                        <p className="text-sm mt-1 mb-0">
                                            <SoftTypography variant="caption"><strong>{t('InvoiceNr.')}: </strong></SoftTypography> <SoftTypography variant="caption">{invoice?.id}</SoftTypography>
                                        </p>
                                        <p className="text-sm mt-0 mb-1"><SoftTypography variant="caption"><strong>{t('Payment')}: </strong></SoftTypography> {
                                            invoice?.payment_items[0]?.paid_ammount == JSON.parse(invoice?.product_details)?.amount ? (
                                                <>
                                                    <SoftTypography variant="caption" style={{color: ((invoice_serial+1)+'/2 Getilgt') == '1/2 Getilgt' ? '#d2a400' : 'success'}} fontWeight="medium" color={((invoice_serial+1)+'/2 Getilgt') == '1/2 Getilgt' ? 'warning' : 'success'} sx={{ lineHeight: 0 }}>
                                                    {t((invoice_serial+1)+'/2 Getilgt')}
                                                    </SoftTypography>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <SoftTypography variant="caption" fontWeight="medium" color="error" sx={{ lineHeight: 0 }}>
                                                    {t('Open')}
                                                    </SoftTypography>   
                                                </>
                                            )
                                        }</p>
                                    </div>
                                </div>                                
                                </>
                            ))}

                            {data.assign_orders && data.assign_orders.map((assign_order) => (
                                <>
                                {assign_order.dachmontag_elektromontage == 'Elektromontage' ? (
                                        <div className="timeline-block mb-3">
                                            <span className="timeline-step">
                                                <i className="ni ni-bell-55 text-success text-gradient" />
                                            </span>
                                            <div className="timeline-content">
                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{assign_order.dachmontag_elektromontage}</h6>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{dateFormat(assign_order.created_at, "dd.mm.yyyy")}</p>
                                                <p className="text-secondary font-weight-bold text-xs m-0">{assign_order.time}</p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Employee')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.employee?.name}</SoftTypography>
                                                </p>

                                                <p className="text-sm m-0">
                                                    <SoftTypography variant="caption"><strong>{t('Stadium')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? 'Successfull' : "Not Successfull"}</SoftTypography>
                                                </p>
                                                <p className="text-sm mb-1">
                                                    <SoftTypography variant="caption"><strong>{t('Abnahme')}: </strong></SoftTypography> <SoftTypography variant="caption">{assign_order?.acabnameprotokoll?.comisioning_status == "1" ? <a target={'_blank'} href={process.env.REACT_APP_BACKEND_URL+'acabname/' + assign_order?.acabnameprotokoll?.id}>AC Abnahmeprotokoll PDF</a> : "--"}</SoftTypography>
                                                </p>

                                                {data.project.corrections && data.project.corrections.map((correction) => (
                                                    <>
                                                        {correction.related == 'auftrag' ? <CorrectionRequestView refresh={refresh} setRefresh={setRefresh} data={correction} serial={Orderserial++}></CorrectionRequestView> : ''}
                                                    </>                                        
                                                ))} 
                                            </div>
                                        </div>
                                    ) : ''
                                }                            
                                </>
                            ))}

                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default Timeline;
