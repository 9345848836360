import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

function ProjectStatus(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [data, setData] = useState(0);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if(props?.projectid){
            setValue('projectid', props.projectid);
        }

        if(props?.quations_id){
            setValue('quations_id', props.quations_id);
        }

        if(props?.status){
            setValue('status', props.status);
        }
    }, [props?.quations_id, props?.projectid, props?.status]); 

    useEffect(() => {
        if(!params.id) return;
        callFetch("project/related/id/get/"+params.id, "GET", []).then((res) => {
            setValue('projectid', res.data.id);
            setValue('status', res.data.status);
        });
    }, [params?.id]);

    const onSubmit = () => {

        var formData = getValues();

        setSaving(true);
        callFetch("project/attribute/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return(
        <>
        {Cookies.get('permissions').indexOf("quotation-update") !== -1 ? (
            <select
                className="form-control"
                {...register("status", {
                    onChange: (e) => {
                        onSubmit();
                    }
                })}
                >
                <option value="">--</option> 
                <option value="1-Verkauft">{t('1-Verkauft')}</option>
                <option value="2-Kontte nicht verkauft werden">{t('2-Kontte nicht verkauft werden')}</option>
                <option value="3-Angebot">{t('3-Angebot')}</option>
                <option value="4-Überleger">{t('4-Überleger')}</option>
                <option value="5-Kunde war nicht da">{t('5-Kunde war nicht da')}</option>
                <option value="6-Kein Interesse / Keine Beratung">{t('6-Kein Interesse / Keine Beratung')}</option>
                <option value="7-Technisch nicht möglich">{t('7-Technisch nicht möglich')}</option>
                <option value="0-Zeitlich nicht geschaft">{t('0-Zeitlich nicht geschaft')}</option>
            </select>
        ) : (
            <select
                className="form-control"
                {...register("status", {})}
                disabled
                >
                <option value="">--</option> 
                <option value="1-Verkauft">{t('1-Verkauft')}</option>
                <option value="2-Kontte nicht verkauft werden">{t('2-Kontte nicht verkauft werden')}</option>
                <option value="3-Angebot">{t('3-Angebot')}</option>
                <option value="4-Überleger">{t('4-Überleger')}</option>
                <option value="5-Kunde war nicht da">{t('5-Kunde war nicht da')}</option>
                <option value="6-Kein Interesse / Keine Beratung">{t('6-Kein Interesse / Keine Beratung')}</option>
                <option value="7-Technisch nicht möglich">{t('7-Technisch nicht möglich')}</option>
                <option value="0-Zeitlich nicht geschaft">{t('0-Zeitlich nicht geschaft')}</option>
            </select>
        )}
        </>
    );
}

export default ProjectStatus;
