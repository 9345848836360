/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const timelineData = [
  {
    color: "dark",
    icon: "notifications",
    title: "Appoinment",
    dateTime: "22 DEC 7:20 PM",
    active:"0"
  },
  {
    color: "dark",
    icon: "text_snippet",
    title: "Quotation",
    dateTime: "21 DEC 11 PM",
    active:"0"
  
  },
  {
    color: "dark",
    icon: "shopping_cart",
    title: "Order",
    dateTime: "21 DEC 11 PM",
    active:"0"
  
  },
  {
    color: "dark",
    icon: "house",
    title: "DC Montage",
    dateTime: "21 DEC 11 PM",
    active:"1"
  
  },
  {
    color: "dark",
    icon: "account_balance_wallet",
    title: "invoice",
    dateTime: "21 DEC 11 PM",
    active:"0"
  },
  // {
  //   color: "info",
  //   icon: "shopping_cart",
  //   title: "Server payments for April",
  //   dateTime: "21 DEC 9:34 PM",
  //   description:
  //     "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //   badges: ["server", "payments"],
  // },
  // {
  //   color: "warning",
  //   icon: "payment",
  //   title: "New card added for order #4395133",
  //   dateTime: "20 DEC 2:20 AM",
  //   description:
  //     "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //   badges: ["card", "#4395133", "priority"],
  // },
  // {
  //   color: "primary",
  //   icon: "vpn_key",
  //   title: "Unlock packages for development",
  //   dateTime: "18 DEC 4:54 AM",
  //   description:
  //     "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //   badges: ["development"],
  // },
  // {
  //   color: "success",
  //   icon: "inbox",
  //   title: "New message unread",
  //   dateTime: "16 DEC",
  //   description:
  //     "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //   badges: ["message"],
  // },
  // {
  //   color: "info",
  //   icon: "campaign",
  //   title: "Notifications unread",
  //   dateTime: "15 DEC",
  //   description:
  //     "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  // },
  // {
  //   color: "warning",
  //   icon: "archive",
  //   title: "New request",
  //   dateTime: "14 DEC",
  //   description:
  //     "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //   badges: ["request", "priority"],
  // },
  // {
  //   color: "secondary",
  //   icon: "sports_esports",
  //   title: "Controller issues",
  //   dateTime: "13 DEC",
  //   description:
  //     "People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of.",
  //   badges: ["controller"],
  //   lastItem: true,
  // },
];

export default timelineData;
