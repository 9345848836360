import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

import Cookies from 'js-cookie';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Step, StepLabel, Stepper } from "@mui/material";
import DCAbnahmeprotokoll from "../OrderProccessing/DCAbnahmeprotokoll";
import ACAbnahmeprotokoll from "../OrderProccessing/ACAbnahmeprotokoll";
import ACabnahmeSign from "../OrderProccessing/ACabnahmeSign";
import DCabnahmeSign from "../OrderProccessing/DCabnahmeSign";

function getSteps() {
    var stepHeaders = [];

    if(Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-read") !== -1){
        stepHeaders.push("1. DC Abnahmeprotokoll");
        stepHeaders.push("2. Sign DC");
    }

    if(Cookies.get('permissions').indexOf("ac_abnahmeprotokoll-read") !== -1){
        stepHeaders.push("3. AC Abnahmeprotokoll");
        stepHeaders.push("4. Sign AC");
    }

    return stepHeaders;
    //return ["1. DC Abnahmeprotokoll","2. Sign DC", "3. AC Abnahmeprotokoll","4. Sign AC"];
}
function OrderProcessing(props) {
    let params = useParams();
    const { t } = useTranslation();
    const user = JSON.parse(Cookies.get('user'));
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const Components = (indexNumber) =>{
        switch(indexNumber){
            case 0:
                return <DCAbnahmeprotokoll project_id={params?.id} user_id={user.id} />;
            case 1:
                return <DCabnahmeSign project_id={params?.id} user_id={user.id} />;
            case 2:
                return <ACAbnahmeprotokoll project_id={params?.id} user_id={user.id} />;
            case 3:
                return <ACabnahmeSign project_id={params?.id} user_id={user.id} />;
        }
    }


    return(
        <>
        <div className="row mb-5">
            <div className="col-lg-12 mt-lg-0">
            <SoftBox mt={1} mb={1}>
                    <Grid justifyContent="center">
                        <Grid item xs={12} lg={8}>
                            <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                                {steps.map((label) => {
                                   if(label === '1. DC Abnahmeprotokoll'){
                                        return <Step id="DCabname" style={{ cursor:"pointer" }} onClick={()=>setActiveStep(0)} key={label}> 
                                        <StepLabel>1. {t('DC Abnahmeprotokoll')}</StepLabel>
                                    </Step>
                                    }else if(label === '2. Sign DC'){
                                        return <Step id="SignDC" style={{ cursor:"pointer" }} onClick={()=>setActiveStep(1)} key={label}> 
                                            <StepLabel >2. {t('Sign DC')}</StepLabel>
                                        </Step>
                                    }else if(label === '3. AC Abnahmeprotokoll'){
                                        return <Step id="ACabname" style={{ cursor:"pointer" }} onClick={()=>setActiveStep(2)} key={label}> 
                                        <StepLabel >3. {t('AC Abnahmeprotokoll')}</StepLabel>
                                        </Step>
                                    }
                                    else{
                                        return <Step id="SignAC" style={{ cursor:"pointer" }} onClick={()=>setActiveStep(3)} key={label}> 
                                            <StepLabel >4. {t('Sign AC')}</StepLabel>
                                        </Step>
                                    }
                                } 
                                )}
                            </Stepper>
                    
                        </Grid>
                    </Grid>
                </SoftBox>
                
                <div className="emb-4">
                    {Components(activeStep)}
                </div>
                <br/> 
            </div>  
        </div>
        <ToastContainer />
        </>
    );
}

export default OrderProcessing;