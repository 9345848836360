import { useMemo, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import dateFormat, { masks } from "dateformat";
import { useForm } from "react-hook-form";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Calendar from "examples/Calendar";
import { Navigate, NavLink, useNavigate, withRouter  } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

// Data
import Cookies from 'js-cookie';
import callFetch from "../../helpers/callFetch";
import { indexof } from "stylis";

function Index() {
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState([]); 
  const [calendarEventsData, setCalendarEventsData] = useState([]);
  const [initialDate, setInitialDate] = useState(dateFormat(new Date(), "yyyy-mm-dd"));
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [statusDescription, setStatusDescription] = useState("");
  const [saving, setSaving] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [projectId, setProjectId] = useState(0);
  const [data, setData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [byDefaultSelectedTeams, setByDefaultSelectedTeams] = useState(null);
  const [serach,setSearch] = useState(0);

  const datePickerRef = useRef(null);
  const employeesRef = useRef(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
} = useForm();
  const toggle = () => {
    setModal(modal);
  };


  const changePostpone = (value) => {
    if(value == 'postpone'){
      datePickerRef.current.style.display = 'block';
      employeesRef.current.style.display = 'none';
    }else if(value == 'send to other person'){
      datePickerRef.current.style.display = 'none';
      employeesRef.current.style.display = 'block';
    }else if(value == 'cancel'){
      datePickerRef.current.style.display = 'none';
      employeesRef.current.style.display = 'none';
    }
  }
  
  const handleEventClick = ({ event, el }) => {
    //toggle(); 
    // console.log(event.title);
    var splitValues =  event.title.split('-');
    // console.log(splitValues[0].trim());
    if(splitValues[0].trim() == 'VK'){
      callFetch("project-event/"+event.id, "GET", []).then((res) => {
        setEmployees(res.employees);
        setValue('date', res.data.date);
        setValue('time', res.data.time);
        setValue('end_time', res.data.end_time);
        setValue('street', res.data.street);
        setValue('code', res.data.code);
        setValue('end_date', res.data.end_date);
        setValue('event_color', res.data.event_color);
        setValue('verkaufer', res.data.verkaufer);
        setValue('vorname', res.data.vorname);
        setValue('name', res.data.name);
        setValue('telefonnummer', res.data.telefonnummer);
        setValue('event_id', ''); 
        setValue('nr', res.data.nr); 
        setValue('description', '');
        setValue('status_description', res.data.status_description); 
        setValue('plz', res.data.plz);
        setValue('miltarbeiter', '');
        setValue('status', res.data.status);
        setValue('ort', res.data.ort);
        if(res.data.file && res.data.file != null && res.data.file != 'null'){
          setAttachment(JSON.parse(res.data.file));
          setValue('file',res.data.file);
        }else{
            setAttachment([]);
        }
  
        const modalInfo = {
          'title'       : getValues('vorname')+' '+getValues('name'),
          'id'          : event.id,  
          'salse_person': res.data.salse_person.name, 
          'desc'        : res.data.description,  
          'start'       : getValues('time'),
          'end_time'    : getValues('end_time')
        };
        setModalData(modalInfo); 
        setIsUpdate(false);  
        var calanderDataOpen = document.getElementById('calanderDataOpen');
        calanderDataOpen.click(); 
      }); 
    }else{ 
      callFetch("event-order-processing/"+event.extendedProps.event_id, "GET", []).then((res) => {
        setEmployees(res.employees);
        setValue('date', res.data.date);
        setValue('time', res.data.time);
        setValue('end_time', res.data.end_time);
        setValue('event_color', res.data.event_color); 
        setValue('code', res.data.code); 
        setValue('event_id', res.data.id);  
        setValue('salse_person_name', res.data.salse_person_name);
        setValue('status', res.data.status);
        setValue('plz', res.data.plz);
        setValue('ort', res.data.ort);
        setValue('street', res.data.street);
        setValue('vorname', res.data.vorname);
        setValue('name', res.data.name);
        setValue('telefonnummer', res.data.telefonnummer);
        setValue('description', res.data.description);
        setValue('status_description', res.data.status_description); 
        setValue('miltarbeiter', res.data.miltarbeiter);
        setValue('verkaufer', '');
        if(res.data.file && res.data.file != null && res.data.file != 'null'){
          setAttachment(JSON.parse(res.data.file));
          setValue('file',res.data.file);
        }else{
            setAttachment([]);
        }
  
        const modalInfo = {
          'title'       : getValues('name'),
          'id'          : event.id,  
          'salse_person': res.data.salse_person_name,  
          'desc'        : res.data.description,  
          'start'       : getValues('time'),
          'end_time'    : getValues('end_time')
        };
        setModalData(modalInfo); 
        setIsUpdate(false);  
        var calanderDataOpen = document.getElementById('calanderDataOpen');
        calanderDataOpen.click(); 
      }); 
    } 
  };


  const handleEventDrop = (event) => { 
    var date = new Date(event.event._instance.range.end);
    var month = Number(date.getMonth())+1;
    var date_day = Number(date.getDate());
    if(month <= 9){
      month = '0'+month;
    }
    if(date_day <= 9){
      date_day = '0'+date_day;
    }
    var formData = new FormData();
    formData.id = event.event._def.publicId;
    formData.date = date.getFullYear()+'-'+month+'-'+date_day;
    formData.color = event.event._def.ui.backgroundColor; 
    formData.event_id = event.event.extendedProps.event_id; 
    
    callFetch("update-event-date", "POST", formData , setError).then((res) => {
        setSaving(false);
        if (!res.ok) return;
        setSubmitSuccess(true);
    }); 
  }

  const handleEventEditClick = (id) => {
    var projectInfoModalClose = document.getElementById('projectInfoModalClose');
    projectInfoModalClose.click(); 
    var projectInfoModalCloseSecond = document.getElementById('projectInfoModalCloseSecond');
    projectInfoModalCloseSecond.click();
    var eventEditModal = document.getElementById('eventEditModalOpen');
    eventEditModal.click();
  }

  function convertToPlain(text=null){
    if(text !== null){
      return text.replace(/<[^>]+>/g, '');
    }else{
      return '';
    } 
  }

  function doSignout(e) {
    e.preventDefault(); 
    callFetch('signout', 'POST', [], null).then(res => {
        Cookies.remove('user');
        Cookies.remove('token');
        var newTabClose = document.getElementById('newTabClose');
        newTabClose.click();
    });
}

useEffect(() => {
  callFetch("my-teams", "GET", []).then((res) => {
    setByDefaultSelectedTeams(res.selected_teams)
    setTeams(res.data);
  });

  
}, [isUpdate,serach]);

const onSubmit = (formData) => {
  setSaving(true);
  if(getValues('event_id')){
    callFetch("update-order-event/"+getValues('event_id'), "POST", formData, setError).then((res) => {
      // console.log(res.message);  
      setSaving(false);
      setIsUpdate(true);
      if(modalData.id){
          setProjectId(modalData.id);
      }
      if (!res.ok) return;
      var eventModalClose = document.getElementById('eventModalClose2');
      eventModalClose.click(); 
  });
  }else{ 
    callFetch("update-project-event/"+modalData.id, "POST", formData, setError).then((res) => {
      // console.log(res.message);  
      setSaving(false);
      setIsUpdate(true);
      if(modalData.id){
          setProjectId(modalData.id);
      }
      if (!res.ok) return;
      var eventModalClose = document.getElementById('eventModalClose');
      eventModalClose.click(); 
  });
  }
  
};

useEffect(() => {
  //filteredCalendarData();  
}, [selectedTeams]);

useEffect(() => {
  if(!teams.length) return;

  if(byDefaultSelectedTeams == null){
    var vals = [];
    for (let [key, team_value] of Object.entries(teams)) {
      vals.push(parseInt(team_value.id));
    }
  }else{
    var vals = JSON.parse(byDefaultSelectedTeams);
  }

  console.log(vals)

  

  filteredCalendarData(vals);
}, [teams]);

const filteredCalendarData = (teamIds = '', mobile_teams = '') => {
  if(teamIds){
    var vals = teamIds;
  }else{
    if(mobile_teams == '') {
      var input_teams = document.getElementsByName('teams[]');
    }else{
      var input_teams = document.getElementsByName('mobile_teams[]');
    }
    var vals = [];
    for (var i=0, n=input_teams.length;i<n;i++) {
      if (input_teams[i].checked){
        vals.push(parseInt(input_teams[i].value));
      }
    }
  }

  var formData = new FormData();
  formData.teams = JSON.stringify(vals);
  setSelectedTeams(vals);

  callFetch("getcalendardatabyteams", "POST", formData, setError).then((res) => {
    setCalendarEventsData(res.data);
    setInitialDate(res.initialDate);  
  });     
}

const searchKey = (key) =>{
  if(key.length > 0){
    callFetch("calendardata/search/"+key, "GET", []).then((res) => {
      setCalendarEventsData(res.data)
    });
  }else{
    setIsUpdate(false)
    setSearch(serach + 1)
  }
}
 
  return (
    <>
    <SoftBox py={3}>
        <SoftBox mb={3}>
          <div className="row">
            <div className="col-md-12">
              <Grid container spacing={0}>
                <Grid item xs={12} lg={3} xl={3} className="d-lg-none"> 
                    <SoftBox mt={3} style={{backgroundColor: '#EEEEEE', paddingLeft: '15px', paddingTop: '12px', borderRadius: '10px'}}>
                      <h5 className="h5cs" style={{borderBottom: '1px solid #ddd', paddingBottom: '5px', color: '#6A6A6A', fontSize: '1.125rem !important', fontWeight: '700 !important'}}>&nbsp;Teams</h5> 
                      <div className="App2" style={{paddingTop: '10px'}}>
                        {teams ? teams.map((team) => (
                          <>
                            <SoftBox className="mb-2" display="flex" alignItems="center">
                              <Checkbox
                                value={team.id}
                                name="mobile_teams[]"
                                defaultChecked={false}
                                checked={selectedTeams.indexOf(team.id) !== -1  ? true : false}
                                onChange={(e) => {
                                  filteredCalendarData('', 'mobile_teams');
                                }} 
                              />
                              <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{team.team_name}</SoftTypography></SoftBox>
                            </SoftBox>
                            {/*
                              <label>
                                <input type="checkbox" value={team.id} name="teams[]" onChange={(e) => { filteredCalendarData() }} />
                                <span className={team.team_type}>{team.team_name}</span>
                              </label>
                            */}
                          </>
                        )): <></>}
                      </div> 
                  </SoftBox>
                </Grid>
                <Grid item xs={12} lg={9} xl={9}>
                    <SoftBox mt={3} className="fullcalanderCustomDesign">
                      {useMemo(
                        () => (
                          <>
                          
                          {/*Cookies.get('permissions').indexOf("Calendar All Events") !== -1 || Cookies.get('permissions').indexOf("Calendar Own Events") !== -1 ? (*/}
                          <Calendar
                          dashboard={true}
                          searchKey={searchKey}
                            initialView="dayGridMonth"  
                            views={{
                              dayGridMonth: {
                                  dayMaxEventRows: 10,
                              },
                              timeGridWeek: {
                                  dayMaxEventRows: 10,                
                              }
                            }}
                            initialDate={initialDate}
                            events={calendarEventsData}
                            eventClick={(e)=>{handleEventClick(e)}}
                            eventDrop={(e)=>{handleEventDrop(e)}}
                            selectable
                            editable
                            buttonText={{
                              today: t('today'),
                              day: t('day'),
                              week: t('week'),
                              month: t('month'),
                              list: t('list'),
                            }}
                            
                            dayHeaderContent={(arg)=> {
                              return [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')][arg.date.getDay()]
                            }}
                            headerToolbar={{
                              left: 'prev,next today',
                              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                            }}
                            
                            eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: false }}
                          />
                          {/*) : <></>}*/}
                          </>
                        ),
                        [calendarEventsData]
                      )}
                    </SoftBox> 
                </Grid>
                <Grid item xs={12} lg={3} xl={3}  className="d-none d-lg-block"> 
                    <SoftBox mt={3} style={{backgroundColor: '#EEEEEE', height: '100%', paddingLeft: '15px', paddingTop: '12px', borderRadius: '0px 10px 10px 0px'}}>
                        
                            <h5 className="h5cs" style={{borderBottom: '1px solid #ddd', paddingBottom: '5px', color: '#6A6A6A', fontSize: '1.125rem !important', fontWeight: '700 !important'}}>&nbsp;Teams</h5> 
                            <div className="App2 team-checkboxs" style={{paddingTop: '10px'}}>
                                {teams ? teams.map((team) => (
                                  <>
                                    <SoftBox className="mb-2" display="flex" alignItems="center">
                                      <Checkbox
                                        value={team.id}
                                        name="teams[]"
                                        defaultChecked={false}
                                        checked={selectedTeams.indexOf(team.id) !== -1  ? true : false}
                                        onChange={(e) => {
                                          filteredCalendarData();
                                        }} 
                                      />
                                      <SoftBox mr={0.7} lineHeight={1}><SoftTypography variant="button" fontWeight="medium">{team.team_name}</SoftTypography></SoftBox>
                                    </SoftBox>
                                    {/*
                                      <label>
                                        <input type="checkbox" value={team.id} name="teams[]" onChange={(e) => { filteredCalendarData() }} />
                                        <span className={team.team_type}>{team.team_name}</span>
                                      </label>
                                    */}
                                  </>
                                )): <></>}
                            </div> 
                    </SoftBox>
                </Grid>              
              </Grid>              
            </div> 
          </div>          

          
        </SoftBox> 
      </SoftBox>


      <button type="button" id="calanderDataOpen" className="btn bg-gradient-primary d-none" data-bs-toggle="modal" data-bs-target="#calanderModal">View Calander Click Event Data</button>
      <button type="button" id="calanderDataOpenAssignOrder" className="btn bg-gradient-primary d-none" data-bs-toggle="modal" data-bs-target="#calanderModalAssingOrder">View Calander Click Event Data</button>
      <div className="modal fade" id="calanderModal" tabindex="-1" role="dialog" aria-labelledby="calanderModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" >
            <div className="modal-header">
              <h6 className="modal-title" id="calanderModalLabel" style={{fontWeight: 'bold'}}>{t('Project Infos')}</h6>
              <button type="button" className="btn-close text-dark" id="projectInfoModalClose" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!getValues('event_id') && (
              <div className="modal-body tb-ac-cs">
              <form onSubmit={handleSubmit(onSubmit)}> 
                 <h6 className="modal-title" id="calanderModalLabel" style={{fontWeight: 'bold'}}>{getValues('code')} - {modalData.salse_person ? modalData.salse_person : ''} - {t('Project Nr')}: {modalData.id ? modalData.id : ''}</h6> 
                 <div style={{marginBottom: '35px'}}>
                     <p className="m-0" style={{fontSize: '12px'}}> {t('Start Date & Time')}:  {getValues('time')} - {dateFormat(getValues('date'), "dd.mm.yyyy")} </p> 
                 </div>
                <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button style={{fontSize: '0.75rem'}} className="nav-link active" id="home1-tab" data-bs-toggle="tab" data-bs-target="#home1-tab-pane" type="button" role="tab" aria-controls="home1-tab-pane" aria-selected="true">Ereignisdetails</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button style={{fontSize: '0.75rem'}} className="nav-link" id="desc-tab" data-bs-toggle="tab" data-bs-target="#desc-tab-pane" type="button" role="tab" aria-controls="desc-tab-pane" aria-selected="false">Beschreibung</button>
                  </li> 
                  <li className="nav-item" role="presentation">
                    <button style={{fontSize: '0.75rem'}} className="nav-link" id="project1-status" data-bs-toggle="tab" data-bs-target="#project1-status-pane" type="button" role="tab" aria-controls="project1-status-pane" aria-selected="false">{t('Project Status')}</button>
                  </li> 
                  <li className="nav-item" role="presentation">
                    <button style={{fontSize: '0.75rem'}} className="nav-link" id="project1-postpone" data-bs-toggle="tab" data-bs-target="#project1-postpone-pane" type="button" role="tab" aria-controls="project1-postpone-pane" aria-selected="false">{t('Postpone')}</button>
                  </li> 
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active pt-3" style={{minHeight: '230.5px'}} id="home1-tab-pane" role="tabpanel" aria-labelledby="home1-tab" tabindex="0">
                    <table className="mt-3 mb-5">
                      <tr style={{lineHeight: '0px'}}>
                        <td width="100"><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Project Nr')}:</SoftTypography></td>
                        <td>
                          <NavLink to={'/project-management/project/edit/' + modalData.id}  data-bs-dismiss="modal" style={{textDecoration: '1px solid'}}><SoftTypography style={{textDecoration: 'underline', color: '#005498'}} variant="caption" color="text">{modalData.id}</SoftTypography></NavLink>
                        </td>
                      </tr>
                      <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Name')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{modalData.salse_person} </SoftTypography></td>
                      </tr>
                      <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Time')}: </SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{modalData.start ? modalData.start : ''}</SoftTypography></td>
                      </tr>
                      <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Date')}: </SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{dateFormat(getValues('date'), "dd.mm.yyyy")}</SoftTypography></td>
                      </tr>
                      <br/>
                       <tr>
                        <td colSpan="2">
                        <SoftTypography variant="caption" fontWeight="bold" color="text" style={{fontSize: '0.75rem', color: '#2D3748'}}>{t('Client Information')}:</SoftTypography> <br/>
                        </td>
                       </tr>
                      <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Name')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('vorname') ? getValues('vorname') : ''} {getValues('name') ? getValues('name') : ''}</SoftTypography></td> 
                      </tr>

                      <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Address')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('street') ? getValues('street') : ''}</SoftTypography></td>
                      </tr>
                     
                      <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('PLZ und Ort')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('plz') ? getValues('plz') : ''}, {getValues('ort') ? getValues('ort') : ''}</SoftTypography></td>
                      </tr>

                       <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Phone')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('telefonnummer') ? getValues('telefonnummer') : ''}</SoftTypography></td>
                      </tr>
                    </table>      
                  </div>
                  <div className="tab-pane fade pt-4" style={{minHeight: '230.5px'}} id="desc-tab-pane" role="tabpanel" aria-labelledby="desc-tab" tabindex="0">
                    <SoftTypography variant="caption" fontWeight="bold" color="text" style={{fontSize: '0.75rem', color: '#2D3748'}}>{t('Project Description')}:</SoftTypography> <br/>
                    {/* <SoftTypography variant="caption" color="text">{modalData.desc}</SoftTypography> */}
                    <SoftTypography variant="caption" color="text" style={{fontSize: '12px', lineHeight: '17px', display: 'block', color: '#2D3748', marginTop: '15px'}}>{modalData.desc}</SoftTypography>
                  </div>
                  <div className="tab-pane fade pt-4" style={{minHeight: '230.5px'}} id="project1-status-pane" role="tabpanel" aria-labelledby="project1-status" tabindex="0">
                    
                                            <div className="col-md-12">
                                              <div className="form-group">
                                                  <label>{t('Project Status')}</label>
                                                  <select
                                                      className="form-control"
                                                      {...register("status", {
                                                          required: true,
                                                      })}
                                                      required style={{fontSize: '0.75rem'}}>
                                                      <option value="">--</option> 
                                                      <option value="1-Verkauft">{t('1-Verkauft')}</option>
                                                      <option value="2-Kontte nicht verkauft werden">{t('2-Kontte nicht verkauft werden')}</option>
                                                      <option value="3-Angebot">{t('3-Angebot')}</option>
                                                      <option value="4-Überleger">{t('4-Überleger')}</option>
                                                      <option value="5-Kunde war nicht da">{t('5-Kunde war nicht da')}</option>
                                                      <option value="6-Kein Interesse / Keine Beratung">{t('6-Kein Interesse / Keine Beratung')}</option>
                                                      <option value="7-Technisch nicht möglich">{t('7-Technisch nicht möglich')}</option>
                                                      <option value="0-Zeitlich nicht geschaft">{t('0-Zeitlich nicht geschaft')}</option>
                                                  </select>
                                                  <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                              </div>
                                          </div>  
                                          <div className="col-sm-12">
                                            <div className="form-group">
                                                  <label>{t('Project Status Description')}</label>
                                                  <textarea {...register("status_description")} className="form-control" style={{fontSize: '0.75rem'}}></textarea>
                                                  <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                              </div>
                                          </div>
                  </div> 

                  <div className="tab-pane fade pt-4" style={{minHeight: '230.5px'}} id="project1-postpone-pane" role="tabpanel" aria-labelledby="project1-status" tabindex="0">
                    
                                            <div className="col-md-12">
                                              <div className="form-group">
                                                  <label>{t('Event Status')}</label>
                                                  <select
                                                      className="form-control"
                                                      {...register("postpone", {
                                                          required: true,
                                                      })}
                                                      required style={{fontSize: '0.75rem'}} onChange={(event) => { changePostpone(event.target.value) }}>
                                                      <option value="">--</option> 
                                                      <option value="postpone">{t('Postpone')}</option>
                                                      <option value="send to other person">{t('Send to Other Person')}</option>
                                                  </select>
                                                  <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                              </div>

                                            <div className="form-group" ref={datePickerRef} style={{ display : 'none'}}>
                                              <label>Date</label>
                                              <input type="date" {...register("event_date")}   className="form-control"/>
                                            </div>

                                            <div className="form-group" ref={employeesRef} style={{ display : 'none'}}>
                                                        <label>
                                                            {t('Verkaufer')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("verkaufer")} 
                                                        >
                                                            <option value="">--</option>
                                                            {employees && employees ? employees.map((employee) => (
                                                                <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>
                                          </div>  
                  </div> 
                </div>  
                <div style={{display: 'flex',
  flexWrap: 'wrap',
  flexShrink: '0',
  alignItems: 'center',
  justifyContent: 'flex-end'}}> 
                    <button type="button" style={{textTransform: 'capitalize', padding: '0.50rem 1.5rem', marginRight: '10px'}} className="btn btn-outline-dark btn-start mt-2" data-bs-dismiss="modal" aria-label="Close">{t('Cancel')}</button> 
                    {/* <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn btn-dark btn-start mt-2">{t('Save')}</button>  */}
                    {!saving && (
                      <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn bg-gradient-primary btn-start mt-2">
                        {t('Save')}
                      </button>
                    )}
                    {saving && (
                      <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn btn-disabled mt-2" disabled>
                        {t('Saving ...')}
                      </button>
                    )} 
                </div>
                </form> 
              </div> 
            )}

            {getValues('event_id') > 0 && (
               <div className="modal-body tb-ac-cs">
               <form onSubmit={handleSubmit(onSubmit)}> 
                  <h6 className="modal-title" id="calanderModalLabel" style={{fontWeight: 'bold'}}>{getValues('code')} - {modalData.salse_person} - {t('Project Nr')}: {modalData.id ? modalData.id : ''}</h6> 
                  <div style={{marginBottom: '35px'}}>
                      <p className="m-0" style={{fontSize: '12px'}}> {t('Start Date & Time')}:  {getValues('time')} - {dateFormat(getValues('date'), "dd.mm.yyyy")} </p> 
                  </div>
                 <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">
                   <li className="nav-item" role="presentation">
                     <button style={{fontSize: '0.75rem'}} className="nav-link active" id="home2-tab" data-bs-toggle="tab" data-bs-target="#home2-tab-pane" type="button" role="tab" aria-controls="home1-tab-pane" aria-selected="true">Ereignisdetails</button>
                   </li>
                   <li className="nav-item" role="presentation">
                     <button style={{fontSize: '0.75rem'}} className="nav-link" id="desc-tab2" data-bs-toggle="tab" data-bs-target="#desc-tab-pane2" type="button" role="tab" aria-controls="desc-tab-pane" aria-selected="false">Beschreibung</button>
                   </li> 
                   <li className="nav-item" role="presentation">
                     <button style={{fontSize: '0.75rem'}} className="nav-link" id="project2-status" data-bs-toggle="tab" data-bs-target="#project2-status-pane" type="button" role="tab" aria-controls="project1-status-pane" aria-selected="false">{t('Project Status')}</button>
                   </li> 
                   <li className="nav-item" role="presentation">
                    <button style={{fontSize: '0.75rem'}} className="nav-link" id="project2-postpone" data-bs-toggle="tab" data-bs-target="#project2-postpone-pane" type="button" role="tab" aria-controls="project2-postpone-pane" aria-selected="false">{t('Postpone')}</button>
                  </li> 
                 </ul>
                 <div className="tab-content" id="myTabContent">
                   <div className="tab-pane fade show active pt-3" style={{minHeight: '230.5px'}} id="home2-tab-pane" role="tabpanel" aria-labelledby="home2-tab" tabindex="0">
                     <table className="mt-3 mb-5">
                       <tr style={{lineHeight: '0px'}}>
                         <td width="100"><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Project Nr')}:</SoftTypography></td>
                         <td>
                           <NavLink to={'/project-management/project/edit/' + modalData.id}  data-bs-dismiss="modal" style={{textDecoration: '1px solid'}}><SoftTypography style={{textDecoration: 'underline', color: '#005498'}} variant="caption" color="text">{modalData.id}</SoftTypography></NavLink>
                         </td>
                       </tr>
                       <tr style={{lineHeight: '0px'}}>
                         <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Name')}:</SoftTypography></td>
                         <td><SoftTypography variant="caption" color="text">{modalData.salse_person} </SoftTypography></td>
                       </tr>
                       <tr style={{lineHeight: '0px'}}>
                         <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Time')}: </SoftTypography></td>
                         <td><SoftTypography variant="caption" color="text">{modalData.start ? modalData.start : ''}</SoftTypography></td>
                       </tr>
                       <tr style={{lineHeight: '0px'}}>
                         <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Date')}: </SoftTypography></td>
                         <td><SoftTypography variant="caption" color="text">{dateFormat(getValues('date'), "dd.mm.yyyy")}</SoftTypography></td>
                       </tr>
                       <br/>
                       <tr>
                        <td colSpan="2">
                        <SoftTypography variant="caption" fontWeight="bold" color="text" style={{fontSize: '0.75rem', color: '#2D3748'}}>{t('Client Information')}:</SoftTypography> <br/>
                        </td>
                       </tr>
                       <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Name')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('vorname') ? getValues('vorname') : ''} {getValues('name') ? getValues('name') : ''}</SoftTypography></td> 
                      </tr>
                     
                       <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Address')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('street') ? getValues('street') : ''}</SoftTypography></td>
                        </tr>
                        <tr style={{lineHeight: '0px'}}>
                          <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('PLZ und Ort')}:</SoftTypography></td>
                          <td><SoftTypography variant="caption" color="text">{getValues('plz') ? getValues('plz') : ''}, {getValues('ort') ? getValues('ort') : ''}</SoftTypography></td>
                        </tr>
                        <tr style={{lineHeight: '0px'}}>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Phone')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('telefonnummer') ? getValues('telefonnummer') : ''}</SoftTypography></td>
                      </tr>
                     </table>      
                   </div>
                   <div className="tab-pane fade pt-4" style={{minHeight: '230.5px'}} id="desc-tab-pane2" role="tabpanel" aria-labelledby="desc-tab" tabindex="0">
                     <SoftTypography variant="caption" fontWeight="bold" color="text" style={{fontSize: '0.75rem', color: '#2D3748'}}>{t('Project Description')}:</SoftTypography> <br/>
                     {/* <SoftTypography variant="caption" color="text">{modalData.desc}</SoftTypography> */}
                     <SoftTypography variant="caption" color="text" style={{fontSize: '12px', lineHeight: '17px', display: 'block', color: '#2D3748', marginTop: '15px'}}>{modalData.desc}</SoftTypography>
                   </div>
                   <div className="tab-pane fade pt-4" style={{minHeight: '230.5px'}} id="project2-status-pane" role="tabpanel" aria-labelledby="project1-status" tabindex="0">
                     
                                             <div className="col-md-12">
                                               <div className="form-group">
                                                   <label>{t('Project Status')}</label>
                                                   <select
                                                       className="form-control"
                                                       {...register("status", {
                                                           required: true,
                                                       })}
                                                       required style={{fontSize: '0.75rem'}}>
                                                       <option value="">--</option> 
                                                       <option value="1-Verkauft">{t('1-Verkauft')}</option>
                                                       <option value="2-Kontte nicht verkauft werden">{t('2-Kontte nicht verkauft werden')}</option>
                                                       <option value="3-Angebot">{t('3-Angebot')}</option>
                                                       <option value="4-Überleger">{t('4-Überleger')}</option>
                                                       <option value="5-Kunde war nicht da">{t('5-Kunde war nicht da')}</option>
                                                       <option value="6-Kein Interesse / Keine Beratung">{t('6-Kein Interesse / Keine Beratung')}</option>
                                                       <option value="7-Technisch nicht möglich">{t('7-Technisch nicht möglich')}</option>
                                                       <option value="0-Zeitlich nicht geschaft">{t('0-Zeitlich nicht geschaft')}</option>
                                                   </select>
                                                   <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                               </div>
                                           </div>  
                                           <div className="col-sm-12">
                                             <div className="form-group">
                                                   <label>{t('Project Status Description')}</label>
                                                   <textarea {...register("status_description")} className="form-control" style={{fontSize: '0.75rem'}}></textarea>
                                                   <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                               </div>
                                           </div>
                   </div> 

                   <div className="tab-pane fade pt-4" style={{minHeight: '230.5px'}} id="project2-postpone-pane" role="tabpanel" aria-labelledby="project1-status" tabindex="0">
                    
                                            <div className="col-md-12">
                                              <div className="form-group">
                                                  <label>{t('Event Status')}</label>
                                                  <select
                                                      className="form-control"
                                                      {...register("postpone", {
                                                          required: true,
                                                      })}
                                                      required style={{fontSize: '0.75rem'}} onChange={(event) => { changePostpone(event.target.value) }}>
                                                      <option value="">--</option> 
                                                      <option value="postpone">{t('Postpone')}</option>
                                                      <option value="send to other person">{t('Send to Other Person')}</option>
                                                  </select>
                                                  <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                                              </div>

                                            <div className="form-group" ref={datePickerRef} style={{ display : 'none'}}>
                                              <label>Date</label>
                                              <input type="date" {...register("event_date")}   className="form-control"/>
                                            </div>

                                            <div className="form-group" ref={employeesRef} style={{ display : 'none'}}>
                                                        <label>
                                                            {t('Verkaufer')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("verkaufer")} 
                                                        >
                                                            <option value="">--</option>
                                                            {employees && employees ? employees.map((employee) => (
                                                                <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>
                                          </div>  
                  </div> 
                 </div>  
                 <div style={{display: 'flex',
   flexWrap: 'wrap',
   flexShrink: '0',
   alignItems: 'center',
   justifyContent: 'flex-end'}}> 
                     <button type="button" style={{textTransform: 'capitalize', padding: '0.50rem 1.5rem', marginRight: '10px'}} className="btn btn-outline-dark btn-start mt-2" data-bs-dismiss="modal" aria-label="Close">{t('Cancel')}</button> 
                     {/* <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn btn-dark btn-start mt-2">{t('Save')}</button>  */}
                     {!saving && (
                       <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn bg-gradient-primary btn-start mt-2">
                         {t('Save')}
                       </button>
                     )}
                     {saving && (
                       <button type="submit" style={{textTransform: 'capitalize', padding: '0.50rem 2rem'}} className="btn btn-disabled mt-2" disabled>
                         {t('Saving ...')}
                       </button>
                     )} 
                 </div>
                 </form> 
               </div> 
            )}
          </div>
        </div>
      </div> 


      <div className="modal fade" id="calanderModalAssingOrder" tabindex="-1" role="dialog" aria-labelledby="calanderModalAssingOrderLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title" id="calanderModalAssingOrderLabel">{t('Project Infos')}</h5>
              <button type="button" className="btn-close text-dark" id="eventModalClose2" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{minHeight: '400px', maxHeight: '400px'}} data-simplebar>
              <SoftTypography>{getValues('code')} - {modalData.title ? modalData.title : ''} - {t('Project Nr')}: {modalData.id ? modalData.id : ''}</SoftTypography>
              <div>
                   <p className="m-0"> Start Date & Time:  {getValues('time')} {dateFormat(getValues('date'), "dd.mm.yyyy")} </p> 
               </div>
              <ul className="nav nav-tabs mt-3" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button style={{color: '#344767',  fontSize: '12px'}} className="nav-link active" id="home2-tab" data-bs-toggle="tab" data-bs-target="#home2-tab-pane" type="button" role="tab" aria-controls="home2-tab-pane" aria-selected="true">Ereignisdetails</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button style={{color: '#344767',  fontSize: '12px'}} className="nav-link" id="desc2-tab" data-bs-toggle="tab" data-bs-target="#desc2-tab-pane" type="button" role="tab" aria-controls="desc2-tab-pane" aria-selected="false">Beschreibung</button>
                </li> 
                <li className="nav-item" role="presentation">
                  <button style={{color: '#344767',  fontSize: '12px'}} className="nav-link" id="project2-status" data-bs-toggle="tab" data-bs-target="#project2-status-pane" type="button" role="tab" aria-controls="project2-status-pane" aria-selected="false">{t('Project Status')}</button>
                </li> 
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active pt-3" id="home2-tab-pane" role="tabpanel" aria-labelledby="home2-tab" tabindex="0">
                    <table>
                      <tr>
                        <td width="100"><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Project Nr')}:</SoftTypography></td>
                        <td>
                          <NavLink to={'/project-management/project/edit/' + modalData.id}  data-bs-dismiss="modal"><SoftTypography variant="caption" color="text">{modalData.id}</SoftTypography></NavLink>
                        </td>
                      </tr>
                      <tr>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Name')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{modalData.salse_person} </SoftTypography></td>
                      </tr>
                      <tr>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Time')}: </SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{modalData.start ? modalData.start : ''}</SoftTypography></td>
                      </tr>
                      <tr>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('Address')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('description') ? getValues('description') : ''}</SoftTypography></td>
                      </tr>
                      <tr>
                        <td><SoftTypography variant="caption" fontWeight="bold" color="text">{t('PLZ und Ort')}:</SoftTypography></td>
                        <td><SoftTypography variant="caption" color="text">{getValues('plz') ? getValues('plz') : ''}, {getValues('ort') ? getValues('ort') : ''}</SoftTypography></td>
                      </tr>
                    </table>  
                    <SoftTypography variant="caption" fontWeight="bold" color="text">{t('Documents')}:</SoftTypography>
                    <SoftBox p={1} className="order-processing">
                                <Grid container spacing={3}>
                                    {attachments ? attachments.map(function(data, i){
                                        return (
                                            <Grid item key={i} className="text-center">
                                                <div>
                                                    <a target="_blank" download href={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+data.attachment}>
                                                        <img src={process.env.REACT_APP_BACKEND_URL+'storage/attachments/'+data.attachment} style={{height: '50px', width: '50px'}}/>
                                                    </a>
                                                </div> 
                                            </Grid>
                                        );
                                    }) : <><Grid></Grid></>}
                                </Grid>
                            </SoftBox>
                </div>
                <div className="tab-pane fade pt-3" id="desc2-tab-pane" role="tabpanel" aria-labelledby="desc2-tab" tabindex="0">
                  <SoftTypography variant="caption" fontWeight="bold" color="text">{t('Project Description')}:</SoftTypography> <br/>
                  <SoftTypography variant="caption" color="text">{modalData.desc}</SoftTypography>
                </div>
                <div className="tab-pane fade pt-3" id="project2-status-pane" role="tabpanel" aria-labelledby="project2-status" tabindex="0">
                  <SoftTypography variant="caption" fontWeight="bold" color="text">{t('Project Status')}:</SoftTypography> <br/>
                  <SoftTypography variant="caption" color="text">{getValues('status') ? getValues('status') : ''}</SoftTypography> <br/>
                  <SoftTypography variant="caption" fontWeight="bold" color="text">{t('Project Status Description')}:</SoftTypography><br/>
                  <SoftTypography variant="caption" color="text">{convertToPlain(statusDescription) }</SoftTypography>
                </div> 
              </div>  
            </div>
            <div className="modal-footer">
              <button type="button" className="btn bg-gradient-default btn-start" data-bs-dismiss="modal" aria-label="Close">{t('Cancel')}</button>
              <button type="button" className="btn bg-gradient-primary btn-start" onClick={ () => handleEventEditClick(modalData.id)}>{t('Edit')}</button>
            </div>
          </div>
        </div>
      </div>
      
      <button type="button" id="eventEditModalOpen" className="btn bg-gradient-primary d-none" data-bs-toggle="modal" data-bs-target="#eventEditModal">View Calander Click Event Data</button>
    



      <div className="modal fade" id="newTabModal" tabindex="-1" role="dialog" aria-labelledby="newTabLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="newTabLabel">{t("")}</h5>
              <button type="button" id="newTabClose" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body apps-icon-link"> 
              
              <div class="grid grid-cols-3 gap-4">
                <NavLink to="/sales-distribution/quations/create"  data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                  <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg>
                  <div class="text-sm font-medium text-gray-900">{t('Neues Angebot')}</div>
                </NavLink>
                <NavLink to="/hr/employees/create" data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                  <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path></svg>
                  <div class="text-sm font-medium text-gray-900">{t('Add Employee')}</div>
                </NavLink>
                <NavLink to="/chat/send-message" data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                  <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z" clip-rule="evenodd"></path></svg>
                  <div class="text-sm font-medium text-gray-900">{t('New Message')}</div>
                </NavLink>
                <NavLink to="/project-management/project/create" data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                    <i class="fa-solid fa-briefcase mx-auto mb-1 w-7 h-7 text-gray-500"></i>
                    <div class="text-sm font-medium text-gray-900">{t('New Project')}</div>
                </NavLink>
                <NavLink to="/settings/roles" data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                  <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                  <div class="text-sm font-medium text-gray-900">Settings</div>
                </NavLink>
                <NavLink to="/supply-chain/products/create" data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                  <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path><path fill-rule="evenodd" d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                  <div class="text-sm font-medium text-gray-900">{t('New Product')}</div>
                </NavLink>
                <NavLink to="/finance/invoices/create" data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                    <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd"></path></svg>
                    <div class="text-sm font-medium text-gray-900">{t('Create Invoice')}</div>
                </NavLink>
                <NavLink to="/sales-distribution/orders" data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                  <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" clip-rule="evenodd"></path></svg>
                  <div class="text-sm font-medium text-gray-900">{t('Create Order')}</div>
                </NavLink>
                <NavLink to="#0" onClick={(e) => doSignout(e)} data-bs-dismiss="modal" className="d-block p-4 text-center rounded-2xl hover:bg-gray-100">
                    <svg class="mx-auto mb-1 w-7 h-7 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path></svg>
                    <div class="text-sm font-medium text-gray-900">Logout</div>
                </NavLink>
              </div> 
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Index;
