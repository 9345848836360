import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


// Data
import timelineData from "layouts/pages/projects/timeline/data/timelineData";
import { useEffect, useState } from "react";
import callFetch from "helpers/callFetch";
import TimelineItem from "./TimelineComponent/TimelineItem";
import TimelineList from "./TimelineComponent/TimelineList";
import dateFormat, { masks } from "dateformat";
import { t } from "i18next";
const Timeline = ({title,project_id,datas}) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(datas);
    },[datas]);


return (
    data && <SoftBox my={0}>
        <Grid container spacing={3}>
          <Grid xs={12} lg={12} ml={8}>
            <TimelineList title={title}>
            {
                    data.project && <TimelineItem
                        color={"dark"}
                        icon={"notifications"}
                        title={t("Appointment")}
                        dateTime={data.project && dateFormat(data.project.date, "dd.mm.yyyy")}
                    />
                }

                {data.quations && data.quations.map((quation) => (
                    <TimelineItem
                        color={"dark"}
                        icon={"text_snippet"}
                        title={t("Quotation")}
                        dateTime={dateFormat(quation.updated_at, "dd.mm.yyyy")}
                    />        
                ))}

                {data.orders && data.orders.map((order) => (
                    <TimelineItem
                        color={"dark"}
                        icon={"shopping_cart"}
                        title={t("Order")}
                        dateTime={dateFormat(order?.order_date, "dd.mm.yyyy")}
                    />
                ))}

                {data.invoices && data.invoices.map((invoice) => (
                    <TimelineItem
                        color={"dark"}
                        icon={"account_balance_wallet"}
                        title={t("Invoice")}
                        dateTime={dateFormat(invoice?.created_at, "dd.mm.yyyy")}
                    />
                ))}

                {data.assign_orders && data.assign_orders.map((assign_order) => (
                    <TimelineItem
                        color={"dark"}
                        icon={"house"}
                        title={t(assign_order.dachmontag_elektromontage)}
                        dateTime={dateFormat(assign_order.created_at, "dd.mm.yyyy")}
                    />
                ))}
            </TimelineList>
          </Grid>
          {/* <Grid item xs={12} lg={6}>
            <TimelineList title="Timeline with dotted line" dark>
              {renderTimelineItems}
            </TimelineList>
          </Grid> */}
        </Grid>
      </SoftBox>
      );
}

export default Timeline