import {React, useState, useEffect} from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import EmployeeIndexTable from "./EmployeeIndexTable";
import Cookies from 'js-cookie';
import SoftSnackbar from "components/SoftSnackbar";
import * as XLSX from 'xlsx';

import ImportModal from "./ImportModal";
function EmployeeIndex() {
    const { t } = useTranslation();
    let { status } = useParams();

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Changes saved successfully"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );

      useEffect(() => {
        console.log(status);
        if(status == 'all'){
            openSuccessSB();
        } 
    }, []);

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                {Cookies.get('permissions').indexOf("employee-create") !== -1 ? (
                    <NavLink to="/hr/employees/create" className="btn btn-icon btn-primary">
                        {t('Add Employee')}
                    </NavLink>
                ) : <></>}
                    <div className=" d-inline-block pull-right">                        
                        {Cookies.get('permissions').indexOf("Import") !== -1 ? (
                            <>
                                <button  className="btn btn-outline-dark me-2" data-bs-toggle="modal" data-bs-target="#employeeimportModal">{t('Import XLSX')}</button>
                                <button className="btn btn-outline-dark mx-3 d-none" id='employeeoutputModal-show-button' data-bs-toggle="modal" data-bs-target="#employeeoutputModal">{t('Output XLSX')}</button>
                            </>
                        ) : <></>}
                        {Cookies.get('permissions').indexOf("employee-create") !== -1 ? (
                            <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'employee-export'} className="btn btn-outline-dark">
                                {t('Export CSV')}
                            </a>
                        ) : <></>}
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Employees')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <EmployeeIndexTable />
                        </div>
                    </div>
                </div>
                {renderSuccessSB}
            </div>
            
            {/* <ImportModal/> */}
        </>
    );
}

export default EmployeeIndex;
