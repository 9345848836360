import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import ProjectIndexTable from './ProjectIndexTable';

function ProjectIndex() {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);

    return (
        <>
            <div className='row'>
                <div className="col-12">
                    <div className="d-sm-flex justify-content-between">
                        {Cookies.get('permissions').indexOf("project-create") !== -1 ? (
                            <NavLink to="/project-management/project/create" className="btn btn-icon btn-primary">
                                {t('Add Project')}
                            </NavLink>
                        ) : <></>}

                        <div className="float-end">
                            {Cookies.get('permissions').indexOf("Import") !== -1 ? (
                                <>
                                    <button  className="btn btn-outline-dark mx-3" data-bs-toggle="modal" data-bs-target="#projectimportModal">{t('Import XLSX')}</button>
                                    <button className="btn btn-outline-dark mx-3 d-none" id='projectoutputModal-show-button' data-bs-toggle="modal" data-bs-target="#projectoutputModal">{t('Output XLSX')}</button>


                                    {JSON.parse(Cookies.get('user')).roleid == 1 ? (
                                        <>
                                            <button  className="btn btn-outline-dark mx-3" data-bs-toggle="modal" data-bs-target="#projectWithSalesimportModal">{t('With Sales Import XLSX')}</button>
                                            <button className="btn btn-outline-dark mx-3 d-none" id='projectWithSalesoutputModal-show-button' data-bs-toggle="modal" data-bs-target="#projectWithSalesoutputModal">{t('With Sales Output XLSX')}</button>
                                        </>
                                    ) : ''}
                                </>
                            ) : <></>}
                            
                            {Cookies.get('permissions').indexOf("Export") !== -1 ? (
                                <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'project-export?token='+Cookies.get('token')} className="btn btn-outline-dark">
                                    {t('Export CSV')}
                                </a>
                            ) : <></>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Projects')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <ProjectIndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectIndex;
