import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function Edit() {
    let params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("designations", "GET", []).then((res) => {
            setDesignations(res.data);
            setEditDelay(editDelay + 1);
        });

        callFetch("departments", "GET", []).then((res) => {
            setDepartments(res.data);
            setEditDelay(editDelay + 1);
        });
    }, [refresh]);

    useEffect(() => {
        if (editDelay > 0)
            callFetch("module/" + params.id + "/edit", "GET", []).then((res) => {
                for (let [key, value] of Object.entries(res.data)) {
                    if(key == 'description'){
                        for (let [description_key, description_value] of Object.entries(JSON.parse(value))) {
                            setValue('description['+description_key+']', description_value);
                        }
                    }else{
                        setValue(key, value);
                    }
                }
            });
    }, [editDelay]);

    const onSubmit = (formData) => {
        setSaving(true);

        formData.description = JSON.stringify(formData.description);
        
        callFetch("module/" + params.id, "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/supply-chain/products/module' /> :
        <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t('Edit Module')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />

                            <div className="row g-3">
                                <div className="col-md-4">
                                    <label>{t('Title')} *</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Short Title')} *</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("short_itle", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.short_itle && errors.short_itle.message}</div>
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Watt')} *</label>
                                    <input
                                        type="number"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("watt", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.watt && errors.watt.message}</div>
                                </div>
                                {/*
                                <div className="col-md-4">
                                    <label>{t('Description')}</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('')}
                                        {...register("description")}></textarea>
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>
                                */}

                                <div className="col-md-12"></div>

                                <div className="col-md-4">
                                    <label>{t('Markenhersteller')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Markenhersteller]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Typ')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Typ]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Zelltyp')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Zelltyp]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Leistungstoleranz')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Leistungstoleranz]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>{t('Modulabmessung')+' ('+t('Width')+')'}</label>
                                            <input
                                                type="number"
                                                step={0.01}
                                                className="form-control mb-4"
                                                placeholder={t('')}
                                                {...register("description[Modulabmessung_width]", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>{t('Modulabmessung')+' ('+t('Height')+')'}</label>
                                            <input
                                                type="number"
                                                step={0.01}
                                                className="form-control mb-4"
                                                placeholder={t('')}
                                                {...register("description[Modulabmessung_height]", {
                                                    required: true,
                                                })}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Gewicht')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Gewicht]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Modulwirkungsgrad')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Modulwirkungsgrad]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Max. Druckbelastung')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[MaxDruckbelastung]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Leistungsgarantie')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Leistungsgarantie]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label>{t('Produktgarantie')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('')}
                                        {...register("description[Produktgarantie]", {
                                            required: true,
                                        })}
                                        required
                                    />
                                </div>
                                
                                <div className="col-12 mb-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default Edit;
