import React from 'react'
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import DachmontageIndexTable from './DachmontageIndexTable';
import AchmotageIndexTable from './AchmotageIndexTable';
const Elektromontage = () => {
  const { t } = useTranslation();
  return (
    <>
       <div className="row">
          <div className="col-12">
              {Cookies.get('permissions').indexOf("quotation-create") !== -1 ? (
                <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'quation-export'} className="btn btn-outline-dark float-end">
                    {t('Export CSV')}
                </a>
            ) : <></>}
          </div>
        </div>
    <div className="row">
        <div className="col-12">
            <div className="card mb-4">
                <div className="card-header pb-0">
                    <h6>{t('Alle Projekte')}</h6>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                    <AchmotageIndexTable/>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Elektromontage