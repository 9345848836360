import { React, useEffect, useState } from 'react';
import Tooltip from "@mui/material/Tooltip";
import SoftAvatar from "components/SoftAvatar";
import callFetch from "../../helpers/callFetch";
function TeamMembers(props) {
    const [teamMembers, setTeamMembers] = useState([]);
    const avatarStyles = {
        border: ({ borders: { borderWidth }, palette: { white } }) =>
            `${borderWidth[2]} solid ${white.main}`,
        cursor: "pointer",
        position: "relative",
        ml: -1.5,
        
        "&:hover, &:focus": {
            zIndex: "10",
        },
    };

    useEffect(() => {
        callFetch("team-members/"+props.id, "GET", []).then((res) => {
            setTeamMembers(res.data);
        }); 
      }, [props.id]); 

    return <>
        {teamMembers.map((item) => (
            <Tooltip title={item.name} placement="top">
                <SoftAvatar src={item.photo ? process.env.REACT_APP_STORAGE_URL + item.photo : '/assets/img/placeholder.png'} alt="" size="sm" sx={avatarStyles} style={{height: 'auto !important'}}/>
            </Tooltip>
        ))}
    </>
}

export default TeamMembers;
