import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";
import { useTranslation } from 'react-i18next'; 
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// NewProduct page components
import Header from "layouts/ecommerce/products/new-product/components/Header";

import Order from './Order';

function getSteps() {
    return ["1. Quotation", "2. Order", "3. Digital Sign"];
}
  
function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (<></>);
      case 1:
        return <Order />;
      default:
        return null;
    }
}


function Index() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const [activeTab, setActiveTab] = useState('auftrag');
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);
console.log(activeStep)
  return (
    <SoftBox mt={1} mb={1}>
        <Grid justifyContent="center">
            <Grid item xs={12} lg={8}>
                <Stepper activeStep={activeStep} alternativeLabel className="my-3">
                    {steps.map((label) => {
                        if(label === '1. Quotation'){
                            return <Step key={label}> 
                            <StepLabel>1. {t('Angebote')}</StepLabel>
                        </Step>
                        }else if(label === '2. Order'){
                            return <Step key={label}> 
                            <StepLabel>2. {t('Auftrag')}</StepLabel>
                        </Step>
                        }else{
                            return <Step key={label}> 
                                <StepLabel>3. {t('Digital Sign')}</StepLabel>
                            </Step>
                        }
                    } 
                    )}
                </Stepper>
                {/*
                <Card sx={{ overflow: "visible" }}>
                    <SoftBox p={2}>
                        <SoftBox>
                            {getStepContent(activeStep)}
                            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            {activeStep === 0 ? (
                                <SoftBox />
                            ) : (
                                <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
                                back
                                </SoftButton>
                            )}
                            <SoftButton
                                variant="gradient"
                                color="dark"
                                onClick={!isLastStep ? handleNext : undefined}
                            >
                                {isLastStep ? "send" : "next"}
                            </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </Card>
                */}
            </Grid>
        </Grid>
    </SoftBox>
  );
}

export default Index;
