import { useEffect, useState } from "react";
function ACPDF(props){
    const [data, setData] = useState([]);
    const [id, setId] = useState([]);
    const [load, setLoad] = useState(false);
    useEffect(() => {
         setData(props.data); 
        //  setId('40000'+props.refresh);
         setLoad(false);
         const interval = setInterval(() => { 
               setId(props.data?.dcabname?.id);
               setLoad(true);
          }, 1000); 
          return () => clearInterval(interval);
    }, [props.refresh, props.data]);
    return  <div> 
     {data && data.dcabname && load ? <iframe src={process.env.REACT_APP_BACKEND_URL+'acabname/'+id+'?page=2'} style={{width: '100%', height: '1123px'}}></iframe> : ''} 
  </div>
}

export default ACPDF;