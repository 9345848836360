import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import { NumericFormat } from 'react-number-format';
import Icon from "@mui/material/Icon";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

function IndexTable(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);

    const tableHeadings = [
        {
            name: t('Payment ID'),
            selector: row => <NavLink to={'/finance/payments/' + row.id + '/add_payment'} className="dropdown-item">{row?.payment_identity}</NavLink>,
            width: '120px', 
            sortable: true,
        },
        {
            name: t('Client Name'),
            selector: row => row.vorname +' '+ row.name,
            sortable: true,
        },
        {
            name: t('Project Nr.'),
            selector: row => row.project_id,
            sortable: true,
        },
        {
            name: t('Payment Status'),
            sortable: true,
            selector: row =>  
            row.status == '1' ? (
                <>
                    <SoftBox display="flex" alignItems="center">
                        <SoftButton variant="outlined" color="success" size="small" iconOnly circular>
                            <Icon sx={{ fontWeight: "bold" }}>done</Icon> 
                        </SoftButton>
                        <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                        &nbsp;&nbsp; {t('2/2 Getilgt')}
                        </SoftTypography>   
                    </SoftBox>
                </>
            ) : row.status == '0' && row.due > 0 && row.paid > 0 ? (
                <>
                    <SoftBox display="flex" alignItems="center">
                        <SoftButton variant="outlined" color="warning" size="small" iconOnly circular>
                            <Icon sx={{ fontWeight: "bold" }}>done</Icon> 
                        </SoftButton>
                        <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                        &nbsp;&nbsp; {t('1/2 Getilgt')}
                        </SoftTypography>   
                    </SoftBox>
                </>
            ) : row.status == '0' &&  row.paid == 0 ? (
                <>
                    <SoftBox display="flex" alignItems="center">
                        <SoftButton variant="outlined" color="error" size="small" iconOnly circular>
                            <Icon sx={{ fontWeight: "bold" }}>sync</Icon> 
                        </SoftButton>
                        <SoftTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                        &nbsp;&nbsp; {t('Open')}
                        </SoftTypography>   
                    </SoftBox>
                </>
            ) : ''
        }, 
        {
            name: t('Forderungen'),
            sortable: true,
            selector: row => (
                <>
                    <NumericFormat 
                        value={Number(row.amount)} 
                        displayType="text" 
                        thousandSeparator={"."} 
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                </>
            ),
        },
        {
            name: t('Getilgt'), 
            selector: row => (
                <>
                    <NumericFormat 
                        value={Number(row.paid)} 
                        displayType="text" 
                        thousandSeparator={"."} 
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={row.currency == 'EUR' ? ' € ' : ' $ '}
                    />
                </>
            ),
            sortable: true,
        }, 
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {Cookies.get('permissions').indexOf("invoice-update") !== -1 ||
                        Cookies.get('permissions').indexOf("invoice-delete") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {Cookies.get('permissions').indexOf("invoice-update") !== -1 ? (
                                    <>
                                        <li>
                                            <NavLink to={'/finance/payments/' + row.id + '/add_payment'} className="dropdown-item">
                                                {t('Add Payment')}
                                            </NavLink>
                                        </li>
                                         
                                    </>
                                ) : <></>}
                                 
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => { 
        // if(props.filter == 'All'){
            callFetch("fetch-payments/"+ props.filter +"?page=" + pageNumber, "GET", []).then((res) => {
                setTableData(res.data); 
                console.log(res.data);
            });
        // }else if(props.filter == 'Paid'){
        //     callFetch("payments/paid?page=" + pageNumber, "GET", []).then((res) => {
        //         setTableData(res.data);
        //         console.log(res.data);
        //     });
        // }else if(props.filter == 'Open'){
        //     callFetch("payments/open?page=" + pageNumber, "GET", []).then((res) => {
        //         setTableData(res.data);
        //         console.log(res.data);
        //     });
        // }
        
    }, [pageNumber, refresh, props.filter]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default IndexTable;
