import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function EmailFormInvoice(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [totalEmails, setTotalEmails] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();

    const onSubmit = (formData) => {



        if(props?.pdf){
            callFetch("invoice/"+formData?.type_id+'?pdf_generate', "GET", []).then((res) =>{
                        if(res?.message == 'success'){
                            formData.pdf = true;
                            setSaving(true);
                            callFetch("sendemail", "POST", formData, setError).then((res) => {
                                setSaving(false);
                                if (!res.ok) return;
                                setSubmitSuccess(true);
                            });
                        }else{
                            console.log('Something wrong!')
                        }
                setSubmitSuccess(true);      
            })
        }else{
            formData.pdf = false;
            callFetch("sendemail", "POST", formData, setError).then((res) => {
                setSaving(false);
                if (!res.ok) return;
                setSubmitSuccess(true);
            });
        }



        /*
        setSaving(true);
        callFetch("sendemail", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
        */
    };

    const addEmail = () => {
        const total = totalEmails+1;
        setTotalEmails(total); 
    }
    const removeEmail = () => {
        const i = totalEmails-1;
        const total = i;
        unregister("email["+i+"]");
        setTotalEmails(total);
    }

    return(
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type={'hidden'} {...register("id")} value={props?.id} />
                <input type={'hidden'} {...register("pdf")} value={props?.pdf} />
                <input type={'hidden'} {...register("type")} value={'invoice'} />
                <div className="form-group">
                    <label>{t('Invoice')} *</label>
                    <select
                        className="form-control"
                        {...register("type_id", {
                            required: true,

                        })}
                        required
                    >
                        <option value={''}>{t('Choose Invoice')}</option>
                        {props.invoices && props.invoices.map((inv) => <option value={inv.id}>{t('Invoice PDF')} {inv.id} </option>  )}
                    </select>
                    <div className="invalid-feedback">{errors.status && errors.status.message}</div>
                </div>                

                {[...Array(totalEmails)].map((x, i) =>
                    <div className="form-group" key={i}>
                        <label>{t('Email')} *</label>
                        <div class="input-group mb-3">
                            <input
                                type="email"
                                {...register("email["+i+"]")}
                                class="form-control"
                                placeholder={t('eg.')+' email@mail.com'}
                            />
                            
                            <div class="input-group-append">
                                {totalEmails == i+1 ? (
                                    <span onClick={()=>{removeEmail()}} style={{cursor: "pointer"}} class="input-group-text border-0">X</span>
                                ) : <></>}
                            </div>
                        </div>
                    </div>
                )}
                
                <div class="form-group mb-3">
                    <a onClick={()=>{addEmail()}}><i class="fa-solid fa-circle-plus"></i>Hinzufügen</a>
                </div>
                
                <div className="row g-3">                     
                    <div className="col-12 my-4">
                        {!saving && (
                            <button type="submit" className="btn btn-primary">
                                {t('Send')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled" disabled>
                                {t('Sending ...')}
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
}

export default EmailFormInvoice;
