import { React, useState } from "react";
import Sidebar from "./Sidebar";
import TopNav from "./TopNav";
import MobileBottomNavigation from "./MobileBottomNavigation/index";
import { Outlet } from "react-router-dom";

function PrivateLayout() {
    const [name, setName] = useState([]);


    return (
        <>
            <Sidebar />
            <main className="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
                <TopNav />
                <div className="container-fluid py-4 min-height-500">
                    <Outlet />
                </div>
            </main>
            <div id="mobile-bottom-navigation">
                <MobileBottomNavigation name={name} />
            </div> 
        </>
    );
}

export default PrivateLayout;
