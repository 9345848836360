import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import IndexTable from './IndexTable';

function Index() {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                {Cookies.get('permissions').indexOf("invoice") !== -1 ? (
                    <NavLink to="/finance/payrolls/create" className="btn btn-icon btn-primary">
                        {t('Add Payroll')}
                    </NavLink>
                ) : <></>}
                
                {Cookies.get('permissions').indexOf("invoice") !== -1 ? (
                    <a target={"_blank"} rel="noreferrer" href={process.env.REACT_APP_BACKEND_URL+'payroll-export'} className="btn btn-outline-dark pull-right">
                        {t('Export CSV')}
                    </a>
                ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Payroll')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
