import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "./EmployeeIndexTablee";

function EmployeeIndexx() {
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <SoftBox my={3}>
    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
      <SoftButton variant="gradient" color="info">
        new order
      </SoftButton>
      <SoftBox display="flex">
        <SoftButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
          filters&nbsp;
          <Icon>keyboard_arrow_down</Icon>
        </SoftButton>
        {renderMenu}
        <SoftBox ml={1}>
          <SoftButton variant="outlined" color="dark">
            <Icon>description</Icon>
            &nbsp;export csv
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
    <Card>
      <DataTable table={dataTableData} entriesPerPage={false} canSearch />
    </Card>
  </SoftBox>
  );
}

export default EmployeeIndexx;
