import { useMemo, useEffect, useState, useRef } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import Checkbox from "@mui/material/Checkbox";

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";

function EvaluationLineChart() {

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const [evaluations, setEvaluations] = useState({
        Ange_Bot: true,
        Auftrag: true,
        In_Rechnung: true,
        Bezahlt: false,
    });

    const [lineChartData, setLineChartData] = useState({});

    useEffect(() => {
        callFetch("statistics/evaluation-line-chart", "POST", evaluations, setError).then((res) => {
            setLineChartData(res.data);
            if (!res.ok) return;
        });
      }, [evaluations]);


    return (
        <>
            <DefaultLineChart
                title=" Auswertung"
                description={
                    <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    >
                    <SoftBox display="flex">
                        <SoftBox
                        component="ul"
                        display="flex"
                        flexDirection="raw"
                        p={0}
                        >
                        <SoftTypography
                            fontWeight="regular"
                            fontSize="13px"
                            marginTop="0.5rem"
                            marginLeft="0.5rem"
                        >
                            <Checkbox
                            checked={evaluations.Ange_Bot}
                            onChange={() =>
                                setEvaluations({
                                    ...evaluations,
                                    Ange_Bot: !evaluations.Ange_Bot,
                                })
                            }
                            />{" "}
                            Angebot
                        </SoftTypography>
                        <SoftTypography
                            fontWeight="regular"
                            fontSize="13px"
                            marginTop="0.5rem"
                            marginLeft="0.5rem"
                        >
                            <Checkbox
                            checked={evaluations.Auftrag}
                            onChange={() =>
                                setEvaluations({
                                    ...evaluations,
                                    Auftrag: !evaluations.Auftrag,
                                })
                            }
                            />{" "}
                            Auftrag
                        </SoftTypography>
                        <SoftTypography
                            fontWeight="regular"
                            fontSize="13px"
                            marginTop="0.5rem"
                            marginLeft="0.5rem"
                        >
                            <Checkbox
                            checked={evaluations.In_Rechnung}
                            onChange={() =>
                                setEvaluations({
                                    ...evaluations,
                                    In_Rechnung: !evaluations.In_Rechnung,
                                })
                            }
                            />{" "}
                            In Rechnung
                        </SoftTypography>
                        <SoftTypography
                            fontWeight="regular"
                            fontSize="13px"
                            marginTop="0.5rem"
                            marginLeft="0.5rem"
                        >
                            <Checkbox
                            checked={evaluations.Bezahlt}
                            onChange={() =>
                                setEvaluations({
                                    ...evaluations,
                                    Bezahlt: !evaluations.Bezahlt,
                                })
                            }
                            />{" "}
                            Bezahlt
                        </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    </SoftBox>
                }
                chart={lineChartData}
            />
        </>
        )
    }
    
export default EvaluationLineChart;