import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next'; 
import Select from 'react-select';

function Edit() {
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [saving, setSaving] = useState(false);
    const [teamMembers, setTeamMembers] = useState(null);
    const [data, setData] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    let { id } = useParams();

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    
    const teamCreateData = (team_type = '') => {
        callFetch("teams/create?team_type="+team_type, "GET", []).then((res) => {
            
            var editData = data;
            editData.manager = res.data.manager;
            editData.members = res.data.members;            
            setData(editData);

            if(data?.data?.team_type == team_type){
                setValue('team_manager', Number(data?.data?.team_manager));
                setValue('teamMembers', data?.data?.team_members);
                setTeamMembers(data?.data?.team_members ? JSON.parse(data?.data?.team_members) : null);
            }else{
                setValue('team_manager', '');
                setValue('teamMembers', '');
                setTeamMembers(null);
            }

            setRefresh(refresh+1);

        });
    }

    useEffect(() => {        
        if(!id) return;
        callFetch("teams/edit/"+id, "GET", []).then((res) => {
            setData(res.data);
            const teamData = res.data.data;
            setValue('team_id', teamData.team_id);
            setValue('name', teamData.team_name);
            setValue('team_type', teamData.team_type);
            setValue('team_manager', Number(teamData.team_manager));
            setValue('teamMembers', teamData?.team_members);
            setTeamMembers(teamData?.team_members ? JSON.parse(teamData?.team_members) : null);
        }); 
    }, [id]);

    useEffect(() => {
        if(!data?.members) return;

        setValue('team_manager', Number(data?.data?.team_manager));

        var employees = [];
        for (var i = 0; i < data?.members.length; i++) {
            employees.push({
                value: data?.members[i].id,
                label: data?.members[i].name
            });
        }
        setEmployees(employees);
    }, [data, refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("teams/update", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

return submitSuccess ? <Navigate to={'/teams/'+getValues('team_type')} /> :
    <div className="row">
        <div className="col-12">
            <div className="card mb-4">
                <div className="card-header pb-0">
                    <h6>{t('Add Team')}</h6>
                </div>
                <div className="card-body">
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="row">
                            <div className="col-md-6">
                                <label>
                                    {t('Team ID')} *
                                </label>
                                <input
                                    type="text"
                                    className="form-control mb-4"
                                    placeholder={t('eg. 123')}
                                    {...register("team_id", {
                                        required: true,
                                    })}
                                    required
                                    readOnly
                                />
                                <div className="invalid-feedback">{errors.team_id && errors.team_id.message}</div>
                            </div>
                            <div className="col-md-6">
                                <label>
                                    {t('Team Name')} *
                                </label>
                                <input
                                    type="text"
                                    className="form-control mb-4"
                                    placeholder={t('eg. Sales Team')}
                                    {...register("name", {
                                        required: true,
                                    })}
                                    required
                                />
                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Team Type')} *</label>
                                    <select
                                        className="form-control"
                                        {...register("team_type", {
                                            required: true,
                                            onChange: (e)=>{
                                                teamCreateData(getValues('team_type'));
                                            }
                                        })}
                                        required
                                        >
                                        <option value="">--</option> 
                                        <option value="sales">{t('Sales Team')}</option> 
                                        <option value="ac">{t('AC Team')}</option> 
                                        <option value="dc">{t('DC Team')}</option> 
                                        
                                    </select>
                                    <div className="invalid-feedback">{errors.team_type && errors.team_type.message}</div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Team Manager')} *</label>
                                    <select
                                        className="form-control"
                                        {...register("team_manager", {
                                            required: true,
                                        })}
                                        required
                                        >
                                        <option value="">--</option> 
                                        {data?.manager ? data?.manager?.map((manager) => (
                                            <option key={manager.id} value={manager.id}>{manager.name}</option>
                                        )) : <></>}
                                    </select>
                                    <div className="invalid-feedback">{errors.team_manager && errors.team_manager.message}</div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{t('Team Members')}</label>
                                    <Select
                                        placeholder={t('Select')}
                                        options={ employees ? employees :  { value: '', label: '---' } }
                                        value={teamMembers}
                                        onChange={(e)=>{
                                            setTeamMembers(e);
                                            setValue('teamMembers', JSON.stringify(e));
                                        }}
                                        isMulti                                
                                    />
                                    <div className="invalid-feedback">{errors.teamMembers && errors.teamMembers.message}</div>
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                       </div>
                    </form>
                </div>
            </div> 
        </div>
    </div>;
}

export default Edit;
