import { useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import dateFormat from "dateformat";
import callFetch from 'helpers/callFetch';
import deleteAlert from 'helpers/deleteAlert';
import SoftTypography from 'components/SoftTypography';
import { NumericFormat } from 'react-number-format';


const UserBaseCustomer = () => {
    const { t } = useTranslation();
    const params = useParams()
    const [customers, setCustomers] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey,setSearchKey] = useState("")

    const tableHeadings = [
        {
            name: t('Project Number'),
            width: '170px',
            selector: row => <NavLink to={'/project-management/project/edit/' + row.id}>{row.id}</NavLink>,
            sortable: true,
        },
        {
            name: t('Name Surname'),
            selector: row => <NavLink to={'/project-management/project/edit/' + row.id}>{row.salse_person && row.salse_person.name ? row.salse_person.name : ''}</NavLink>,
        },
        {
            name: t('Date'),
            width: '120px',
            selector: row => dateFormat(row.created_at, "dd.mm.yyyy"),
        },
        {
            name: t('Status'),
            width: '250px',
            cell: row => (
                <>
                    <span style={{height: '0.375rem', width: '0.375rem', backgroundColor: row.quations.status == '1-Verkauft' ?
                                '#21d4fd' : row.quations.status == '2-Kontte nicht verkauft werden' ? 
                                '#ff0080' : row.quations.status == '3-Angebot' ? 
                                '#fbcf33' : row.quations.status == '4-Überleger' ? 
                                '#e6980b' : row.quations.status == '5-Kunde war nicht da' ? 
                                '#9320bb' : row.quations.status == '6-Kein Interesse / Keine Beratung' ? 
                                '#9320bb' : row.quations.status == '7-Technisch nicht möglich' ? 
                                '#9320bb' : row.quations.status == '0-Zeitlich nicht geschaft' ? 
                                '#9320bb' : 'dark', borderRadius: '50%', display: 'inline-block'}}></span>
                    <SoftTypography style={{position: 'relative', top: '1px', left: '3px', fontSize: '0.75rem', display: 'inline'}}>{row.quations.status}</SoftTypography>
                </>
            ),
        },
        {
            name: t('Price'),
            selector: row => ((
                <NumericFormat 
                        value={Number(row.quations.gesami_netto)} 
                        displayType="text" 
                        thousandSeparator={"."} 
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={' € '}
                    />
            )),
        },
        {
            name: t('Ort'),
            selector: row => row.ort,
        },
        // {
        //     name: t('Project Status'),
        //     selector: row => <>
        //         <div onClick={handleOpenMenu} className="d-flex align-items-center cursor-pointer" style={{ color: "#0048B1", textDecoration:"underline" }}>
        //             <span onClick={()=>projectData(row.id)} className="d-flex align-items-center cursor-pointer">
        //                 <Icon>info</Icon>&nbsp; {t('AC Montage')}
        //             </span>
        //         </div>
               
        //     </>,
            
        // },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {Cookies.get('permissions').indexOf("project-update") !== -1 ||
                        Cookies.get('permissions').indexOf("project-delete") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {Cookies.get('permissions').indexOf("project-update") !== -1 ? (
                                    <>
                                        <li>
                                            <NavLink to={'/project-management/project/edit/' + row.id} className="dropdown-item">
                                                {t('Edit')}
                                            </NavLink>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                    </>
                                ) : <></>}
                                {Cookies.get('permissions').indexOf("project-delete") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'quations', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("customers/" + params.id, "GET", []).then((res) => {
            setCustomers(res.data.base_customer);
        });
    }, [pageNumber, refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

useEffect(()=>{
    if(searchKey.length > 0){
        callFetch('customer/search/'+searchKey, "GET", []).then((res)=>{
            setCustomers(res.data)
        })
    }else{
        setRefresh(refresh + 1)
    }
},[searchKey])




    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = customers.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === customers.last_page;
        const previosDisabled = currentPage === 1;

  return (
    <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {customers.from} {t('to')} {customers.to} {t('of')} {customers.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
  )
}
return <DataTable
columns={tableHeadings}
data={customers?.data}
noDataComponent={t('There are no records to display')}
pagination
highlightOnHover
paginationComponentOptions={{ noRowsPerPage: true }}
paginationServer
paginationTotalRows={customers?.total}
onChangePage={handlePageChange}
paginationComponent={BootyPagination}
// subHeader
// subHeaderComponent={<input type="text" placeholder={ t("Search...") } className='form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} />}
/>;
}

export default UserBaseCustomer