import React, { useState, useRef, useEffect } from "react";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import Cookies from "js-cookie";
import callFetch from "helpers/callFetch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CardCarousel from "./component/CardCarousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./component/slider.css"
import { AnimatePresence, motion,LazyMotion, domAnimation, m } from "framer-motion";

import CardData from './CardData';
import Calendar from '../../../pages/calendar/Index'; 
import NewPlan from "./NewPlan";
import OverviewList from "./component/OverviewList";

const EmployeesDashboard = () => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);

  const dashboardRefresh=({msg})=>{
    alert(msg)
    setRefresh(1)
  }

  return (
    <>
      <SoftBox my={3}>
        <SoftBox mb={3}>
          <SoftBox pb={0}>
            <CardData />
          </SoftBox>

          <Grid container my={1} spacing={3}>
            <Grid item xs={12} lg={12}>
              <Calendar />
            </Grid>
            <Grid item xs={12} lg={12}>
              <OverviewList dashboardRefresh={dashboardRefresh} />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>

      {/*<NewPlan project_nr={fieldService.project_nr} title={fieldService.title} setRefresh={setRefresh} />     */}

    </>
  );
};

export default EmployeesDashboard;
