import { React, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import callFetch from "../helpers/callFetch";
import { useTranslation } from 'react-i18next';
function Signin() {
    const { t } = useTranslation();
    let tempAuth = Cookies.get('token') ? true : false;
    const [signinSuccess, setSigninSuccess] = useState(tempAuth);
    const [passwordError, setPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('dashboard');
    const [emailErrorTwo, setEmailErrorTwo] = useState(false);
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const { i18n } = useTranslation();

    const onSubmit = (formData) => {
        setPasswordError(false);
        setEmailError(false);
        callFetch('signin', 'POST', formData, setError)
            .then(loginData => {

                let l = { flag: 'de', lang: 'de', name: 'Deutsch' }
                i18n.changeLanguage(l.lang);
                Cookies.set('lang', JSON.stringify(l));


                if(loginData.message == 'The entered password is invalid.'){
                    setPasswordError(true);
                    setEmailError(false);
                    setEmailErrorTwo(false); 
                    return;
                }
                if(loginData.message == 'The email must be a valid email address.'){
                    setEmailErrorTwo(true);
                    setEmailError(false);
                    setPasswordError(false);
                    return;
                }
                if(loginData.message == "The email address you entered isn't connected to an account."){
                    setEmailError(true);
                    setEmailErrorTwo(false);
                    setPasswordError(false);
                    return;
                }
                if (!loginData.ok)
                    return;
                setPasswordError(false);
                setEmailError(false);
                Cookies.set('token', loginData.data.token);
                Cookies.set('user', JSON.stringify({
                    id: loginData.data.user.id,
                    name: loginData.data.user.name,
                    email: loginData.data.user.email,
                    mobile: loginData.data.user.mobile,
                    gender: loginData.data.user.gender,
                    date_of_birth: loginData.data.user.date_of_birth,
                    address: loginData.data.user.address,
                    photo: loginData.data.user.photo,
                    rolename: loginData.data.user.roles[0].name,
                    roleid: loginData.data.user.roles[0].id,
                }));
                
                /*
                Cookies.set('permissions', JSON.stringify(loginData.data.permissions));
                if(!Cookies.get('permissions')){
                    Cookies.set('permissions', loginData.data.permissions);
                }
                */
                Cookies.set('permissions', loginData.data.permissions);

                if(loginData?.data?.user?.roles[0]?.id == 7 || loginData?.data?.user?.roles[0]?.id == 8){
                    setRedirectUrl('field-service/overview');
                }

                setTimeout(() => {
                    //window.location.href = process.env.REACT_APP_FRONTEND_URL + redirectUrl;
                }, 1500);
                setSigninSuccess(true);
            });
    }

    return signinSuccess ? <Navigate to={'/'+redirectUrl}/> :
        <>
            <main className="main-content  mt-0">
                <section>
                    <div className="page-header min-vh-75">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                                    <div className="card card-plain mt-8">
                                        <div className="card-header text-center bg-transparent">
                                            <img src="/assets/img/logo.png" className="img-fluid login-logo" alt="" />
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                                <label>Email</label>
                                                <div className="mb-3">
                                                    <input type="email" className={emailError || emailErrorTwo ? 'form-control is-invalid' : 'form-control'} placeholder="Email" {
                                                        ...register('email', {
                                                            required: true
                                                        })
                                                    } required />
                                                    {emailError && (
                                                    <div className="invalid-feedback">
                                                        The email address you entered isn't connected to an account.
                                                    </div>
                                                    )}
                                                    {emailErrorTwo && (
                                                    <div className="invalid-feedback">
                                                        The email must be a valid email address.
                                                    </div>
                                                    )}
                                                </div>
                                                <label>{t('Password')}</label>
                                                <div className="mb-3">
                                                    <input type="password" className={passwordError ? 'form-control is-invalid' : 'form-control'} placeholder={t('Password')} {
                                                            ...register('password', {
                                                                required: true
                                                            })
                                                        } required />
                                                    {passwordError && (
                                                        <div className="invalid-feedback">
                                                            The entered password is invalid.
                                                        </div>
                                                    )}
                                                    
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" style={{borderColor: '#e9ecef'}} type="checkbox" {...register("checkbox")} />
                                                    <label className="form-check-label" style={{color: '#344767'}} htmlFor="rememberMe">{t('Remember me')}</label>
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="btn btn-primary w-100 mt-4 mb-0">{t('Sign In')}</button>
                                                </div>
                                            </form>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="footer py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-8 mx-auto text-center mt-1">
                            <p className="mb-0 text-secondary powerdby">
                                Powered by <img src="/assets/img/poweredby.png" />
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>;
}

export default Signin;
