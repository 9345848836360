import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import team4 from "assets/images/team-4.jpg";
import Icon from "@mui/material/Icon";
import SoftBadge from "components/SoftBadge";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";


import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderProcessing from "../create/OrderProcessing";
import SkizzeModulbelegung from "./SkizzeModulbelegung";
import SignaturePad from 'react-signature-pad-wrapper';

const DCabnahmeEdit = ({edit_id,project_id}) => {
    const { t } = useTranslation();
    const notify = () => toast("Success");
    const errorNotify = () => toast("Fill Out Skizze/Modulbelegung");
    const params = useParams()
    const user = JSON.parse(Cookies.get('user'));
    const signaturePadRef = useRef(null);
    const submitBtn = useRef();
    const [saving, setSaving] = useState(false);
    const [modules, setModules] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay,setEditDelay] = useState(0)
    const [canEdit, setCanEdit] = useState(false)
    const [refresh2, setRefresh2] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [description, setDescription] = useState("");
    const [attachments, setAttachment] = useState([]);
    const [data, setData] = useState([]);
    const [link,setLink] = useState("")
    const [id,setID] = useState(0)
  
    const {
      register,
      handleSubmit,
      setError,
      setValue,
      getValues,
      reset,
      formState: { errors },
  } = useForm();

    useEffect(()=>{
        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    },[0])

    useEffect(()=>{
        if(Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-update") !== -1 ){
            setCanEdit(true);
        }else{
            setCanEdit(false);
        }

    },[0])

    useEffect(()=>{
        if(project_id !== undefined){
            callFetch("dc_abnahmeprotokoll/"+project_id+"/edit","GET",[]).then((res)=>{
                setDescription(res?.data?.dcabname.description)
                signaturePadRef.current.fromDataURL(res?.data?.dcabname?.drawing);
                setValue('modultyp',res?.data?.dcabname?.modultyp)
                setValue('modulanzahl',res?.data?.dcabname.modulanzahl)
                setLink(res?.data?.pdf)
                for (let [key, value] of Object.entries(res?.data?.dcabname)) {
                    setValue(key, value);
                }
            })
        }
    },[project_id,refresh,editDelay])

    useEffect(()=>{

        if(editDelay > 0){
            setRefresh(1)
        }
    },[editDelay])

    const onSubmit = (formData) => {
        formData.description = description;
        formData.sign = signaturePadRef.current.toDataURL('image/png');
        if(signaturePadRef.current.isEmpty()){
          errorNotify()
          return;
        } 
        callFetch("dc_abnahmeprotokoll/"+edit_id, "POST", formData, setError).then((res) => {
            setData(res);
            setLink(res?.data?.pdf)
            setID(res?.data?.id)
            Cookies.set('dc_pdf',res?.data?.id)
            if (!res.ok) return;
            setSubmitSuccess(true);
            setEditDelay(1)
            notify();
      });
    };
  return (
    <>
      <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input disabled={canEdit ? false : true} type="hidden" defaultValue="PUT" {...register("_method")} />
                <SoftBox>
                    <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link ? link:"#"} ></a>
                    <input disabled={canEdit ? false : true} type="hidden" id="dcid" value={id} />
                    <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('DC Abnahmeprotokoll')}</SoftTypography>
                        </SoftBox>
                        <SoftBox p={2} lineHeight={1}>
                        <Grid mb={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 1</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Strg 1" {...register('strg_1',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_1 && errors.strg_1.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 2</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Strg 2" {...register('strg_2',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_2 && errors.strg_2.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 3</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Strg 3" {...register('strg_3',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_3 && errors.strg_3.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 4</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Strg 4" {...register('strg_4',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_4 && errors.strg_4.message}</div>
                                </Grid>
                        </Grid>
    
    
                        {/* str start from harea */}
    
                        <Grid mb={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 1</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Str 1" {...register('str_1',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_1 && errors.str_1.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 2</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Str 2" {...register('str_2',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_2 && errors.str_2.message}</div>
    
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 3</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Str 3" {...register('str_3',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_3 && errors.str_3.message}</div>
    
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 4</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Str4" {...register('str_4',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_4 && errors.str_4.message}</div>
                                </Grid>
                        </Grid>
                        {/* <SoftBox pl={3} mb={2} >
                            <SoftTypography variant="h6">{t('Anlagendaten')}</SoftTypography>
                        </SoftBox> */}
                        <Grid my={2} container  spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Modultyp</label>
                                    <select disabled={canEdit ? false : true} type="text" className=" form-control" {...register('modultyp',{required: true})} required>
                                        <option>--</option>
                                        {
                                            modules && modules.map((modules,index)=>{
                                                return <option key={index} value={modules.id}>{modules.name}</option>
                                            })
                                        }
                                        
                                    </select>
                                    <div className="invalid-feedback">{errors.modultyp && errors.modultyp.message}</div>
                                </Grid>
    
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Modulanzahl</label>
                                    <input disabled={canEdit ? false : true} type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl',{required: true})} required />
                                    <div className="invalid-feedback">{errors.modulanzahl && errors.modulanzahl.message}</div>
                                </Grid>
                        </Grid>
    
    
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Stecker System</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Stecker System" {...register('stecker_system',{required: true})} required />
                                    <div className="invalid-feedback">{errors.stecker_system && errors.stecker_system.message}</div>
    
                                </Grid>
    
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Montagesystem</label>
                                    <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Montagesystem" {...register('montagesystem',{required: true})} required />
                                    <div className="invalid-feedback">{errors.montagesystem && errors.montagesystem.message}</div>
    
                                </Grid>
                        </Grid>
    
    
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Erdungsanschluss</label>
                                    {/* <input disabled={canEdit ? false : true} type="text" className=" form-control" placeholder="Erdungsanschluss" {...register('erdungsanschluss',{required: true})} required /> */}
                                        <div className="d-flex align-items-center justify-content-between mt-2" >
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >Pot.schine</label>
                                                <div className="form-check d-inline-block">
                                                    <input disabled={canEdit ? false : true} 
                                                        type="checkbox"
                                                        {...register("pot_schine")}
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >Erdspieß</label>
                                                <div className="form-check d-inline-block">
                                                    <input disabled={canEdit ? false : true} 
                                                        type="checkbox"
                                                        {...register("erdspieß")}
                                                        required
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">{errors.erdungsanschluss && errors.erdungsanschluss.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an der PV Anlage</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('pv_system',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.pv_system && errors.pv_system.message}</div>
    
                                </Grid>
                        </Grid>
    
    
                        
                        <Grid my={2} container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                 <label>Sichtbare Schäden am Dach</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('the_roof',{required: true})} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>      
                                    <div className="invalid-feedback">{errors.the_roof && errors.the_roof.message}</div>
    
                        </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Gebäude</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('to_build',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
    
                                </Grid>
                        </Grid>
        
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Spannungsprotokoll erstellt</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('log_created',{required: true})} >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.log_created && errors.log_created.message}</div>
    
                                </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an Verkablung, Stecker</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('wiring_plugs',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.wiring_plugs && errors.wiring_plugs.message}</div>
    
                                </Grid>
                        </Grid>
        
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an Leitungsführung</label>
                                    
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('to_the_wiring',{required: true})} require>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.to_the_wiring && errors.to_the_wiring.message}</div>
    
                                </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Montagesystem</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('mounting_system',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
                        </Grid>
        
                        <Grid my={2} container spacing={2} >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Wechselrichter</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('the_inverter',{required: true})} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Baustelle sauber hinterlassen</label>
                                    <select disabled={canEdit ? false : true} className=" form-control" {...register('site_clean',{required: true})} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
                        </Grid>                
    
    
                        <Grid my={2} container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="center" >
                                <div className="form-check d-inline-block">
                                    <input disabled={canEdit ? false : true} 
                                        type="checkbox"
                                        {...register("leistungsoptim_check")}
                                        required
                                        className="form-check-input"
                                        value={1}
                                    />
                                </div>
                                <label className="mt-2" >Leistungsoptim</label>
                                <input disabled={canEdit ? false : true} className=" form-control ms-2" {...register("leistungsoptim",{
                                    required:true
                                })} required/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="center" >
                                <label>Stk</label>
                                <input disabled={canEdit ? false : true} className=" form-control ms-2" placeholder="Stk" {...register("stk",{
                                    required:true
                                })} required />
                            </Grid>
                        </Grid>
    
    
                            <Grid container mt={1} spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                     <div className="d-flex align-items-center justify-content-between mt-4" >
                                            <label>Verkablung / Leitungsführung:</label>
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >In Ordnung</label>
                                                <div className="form-check d-inline-block">
                                                    <input disabled={canEdit ? false : true} 
                                                        type="checkbox"
                                                        {...register("in_ordnung")}
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                        >
                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Beanstandet')}</SoftTypography>
                                        </SoftBox>
                                        <SoftInput 
                                            placeholder={t('Beanstandet')}
                                            {...register("beanstandet", {
                                                required: true,
                                            })}
                                            required
                                        />
        
                                    </SoftBox>
                                </Grid>
                            </Grid>
        
                            <Grid container spacing={2} my={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                     <div className="d-flex align-items-center justify-content-between mt-4" >
                                            <label>Asführung entspricht dem Angebot:</label>
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >In Ordnung</label>
                                                <div className="form-check d-inline-block">
                                                    <input disabled={canEdit ? false : true} 
                                                        type="checkbox"
                                                        {...register("in_ordnung2")}
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                        >
                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Beanstandet')}</SoftTypography>
                                        </SoftBox>
                                        <SoftInput 
                                            placeholder={t('Beanstandet')}
                                            {...register("beanstandet2", {
                                                required: true,
                                            })}
                                            required
                                        />
        
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            
        
                                            
        
                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                                >
                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        {t('Description')}
                                    </SoftTypography>
                                </SoftBox>
                                {canEdit ? (
                                    <SoftEditor
                                        value={description}
                                        onChange={setDescription}
                                    />
                                ) : (
                                    <div dangerouslySetInnerHTML={ {__html: description} }></div>
                                )}
                            </SoftBox>
                        </SoftBox>

                        {canEdit ? (
                        <>
                        <SoftBox  p={2} display="flex" justifyContent="flex-end">
                            <a href="#" className="btn btn-danger mx-2 d-none">
                                {t('Cancel')}
                            </a>
        
                            {!saving && (
                                <button  id="dcAbname" type="submit" className="btn btn-primary d-none">
                                    {t('Save')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled d-none" disabled>
                                    {t('Saving ...')}
                                </button>
                            )}
                        </SoftBox>
                        </>
                         ) : <></>}
                    </Card>
                </SoftBox>
                <SoftBox my={2}>
                    <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t(' Skizze/Modulbelegung')}</SoftTypography>
                        </SoftBox>
    
                        <SignaturePad options={{minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)'}} ref={signaturePadRef} />


                        {canEdit ?                       
                        <Grid container px={2}>
                            <Grid item sx={12} md={12} display="flex" justifyContent="center">
                                <button type="button" className="btn btn-outline-dark"  onClick={() => { signaturePadRef.current.clear(); }}>
                                    <i className="fa fa-eraser"></i>
                                </button> 
                            </Grid>
                        </Grid>
                        : ''}    
                    </Card>
                </SoftBox>
            </form>
    </>
  )
}

export default DCabnahmeEdit