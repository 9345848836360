import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Select from 'react-select';
import SoftSnackbar from "components/SoftSnackbar";
import Timeline from "./Timeline";
import Cookies from 'js-cookie';

import ProjectStatus from '../ProjectStatus';

function CallCenter(props) {
    let params = useParams();
    const { t } = useTranslation();
    const submitBtn = useRef();
    const [data, setData] = useState([]);
    const [zipcode, setZipcode] = useState([]);
    const [selectedPlz, setSelectedPlz] = useState({ value: '', label: '---' });
    const [city, setCity] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [files, setFiles] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [projectId, setProjectId] = useState(0);
    const [create, setCreate] = useState(true);
    const [formdata, setFormdata] = useState({});
    const [successSB, setSuccessSB] = useState(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title={t('Success')}
          //content={t("Changes saved successfully")}
          dateTime={t("")}
          open={successSB}
          onClose={() => setSuccessSB(false)}
          close={() => setSuccessSB(false)}
          bgWhite
        />
      );
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    

    //plz and ort load
    useEffect(() => {
        callFetch("address/zipcode", "GET", []).then((res) => {
            if(res.message == 'success'){
                setZipcode(res.data);
            }
        });
    }, [refresh]);
    

    //Edit Data load
    useEffect(() => {
        flatpickr(".flat-pickr");
        if(props?.update && props.update == true && params?.id){
            setCreate(false);

            callFetch("projects/" + params.id + "/edit?date="+getValues('date')+'&time='+getValues('time'), "GET", []).then((res) => {
                setData(res);
                for (let [key, value] of Object.entries(res.data)) {
                    if(key == 'date'){
                        setValue(key, value);
                    }else if(key == 'plz'){
                        setValue(key, value);
                    }else{
                        setValue(key, value);
                    }
                }
            });
        }        
    }, [params?.id]);

    //verkaufer id set
    useEffect(() => {
        setValue('verkaufer', data?.data?.verkaufer);
    }, [data]);
    
    //zipcode fetch
    useEffect(() => {
        for (let [zipcode_key, zipcode_value] of Object.entries(zipcode)) {
            if(zipcode_value.label == getValues('plz')){
                setSelectedPlz(zipcode_value)
            }
        }
    }, [params?.id, refresh, getValues('plz')]);
    
    //verkaufer fetch base on date time
    const verkauferFeatch = () => {
        if(params?.id && props?.update && props.update == true){
            callFetch("projects/" + params?.id + "/edit?date="+getValues('date')+'&time='+getValues('time'), "GET", []).then((res) => {
                setData({
                    employees: res.employees
                })                
            });
        }else{
            callFetch("projects/create?date="+getValues('date')+'&time='+getValues('time'), "GET", []).then((res) => {
                setData(res.data);
            });
        }
    }
    
    //Form Submit
    const onSubmit = (formData) => {
        setSaving(true);
        callFetch(create == true ? "projects" : "projects/"+ params?.id, "POST", formData, setError).then((res) => {
            setSuccessSB(true); 
            setSaving(false);
            setRefresh2(refresh2 + 1);

            if(res.project_id){
                setProjectId(res.project_id);
            }
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    useEffect(() => {
        if(params?.status == 'new'){
            setSuccessSB(true);
        } 
    }, [params?.status]);
    
    /*
    useEffect(() => {
        setRefresh2(refresh2 + props.refresh); 
    }, [refresh, props.refresh]);
    */
    
    /*    
    useEffect(() => {
        setFormdata(getValues());
    }, [getValues()]);
    */
    

    return submitSuccess && create ? <Navigate to={'/project-management/project/edit/'+projectId+'/new'} /> :
        <>
        
        <div className="row mb-5">
            <div className="col-lg-9 mt-lg-0">
                <div className="row">
                    <div className="col-md-12">
                        <div className="emb-4">
                                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                    {create == false ? (
                                        <input type="hidden" defaultValue="PUT" {...register("_method")} />
                                    ): <></>}
                                    <div className="row g-3">
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="form-group">
                                                        <label for="example-date-input" class="form-control-label">{t('Date')}</label>
                                                        
                                                        <input class="form-control" className="form-control"
                                                            {...register("date", {
                                                                required: true,
                                                                onChange: (e) => {
                                                                    verkauferFeatch();
                                                                }
                                                            })}
                                                            required
                                                            type="date" id="example-date-input" />
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label>
                                                            {t('Time')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("time", {
                                                                required: true,
                                                                onChange: (e) => {
                                                                    verkauferFeatch();
                                                                }
                                                            })}
                                                            required
                                                        >
                                                            <option value="08:00">08:00 - 10:00</option>
                                                            <option value="10:00">10:00 - 12:00</option>
                                                            <option value="12:00">12:00 - 14:00</option>
                                                            <option value="14:00">14:00 - 16:00</option>
                                                            <option value="16:00">16:00 - 18:00</option>
                                                            <option value="18:00">18:00 - 20:00</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            {t('Verkaufer')} *
                                                        </label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            {...register("verkaufer", {
                                                                required: true,
                                                            })}
                                                            required
                                                        >
                                                            <option value="">--</option>
                                                            {data && data.employees ? data.employees.map((employee) => (
                                                                <option key={employee.user.id} value={employee.user.id}>{employee.user.name}</option>
                                                            )) : <></>}
                                                        </select>
                                                        <div className="invalid-feedback">{errors.quotation_id && errors.quotation_id.message}</div>
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <label>
                                                            {t('Beschreibung')}
                                                        </label>
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            placeholder={t('eg. 1234 My street, City')}
                                                            {...register("description")}></textarea>
                                                        <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label>{t('Anrede')} *</label>
                                                        <select
                                                            className="form-control"
                                                            {...register("anrede", {
                                                                required: true,
                                                            })}
                                                            required>
                                                            <option value="">--</option>
                                                            <option value="Herr">{t('Herr')}</option>
                                                            <option value="Frau">{t('Frau')}</option>
                                                        </select>
                                                        <div className="invalid-feedback">{errors.anrede && errors.anrede.message}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Vorname')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("vorname", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.vorname && errors.vorname.message}</div>
                                                            </div>                                            
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Name')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("name", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                                            </div>                                            
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <div className="form-group">
                                                                <label>{t('Straße')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("street", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.street && errors.street.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>{t('Nr')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("nr", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                                            </div>                                            
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('PLZ')} *</label>

                                                                <Select
                                                                    placeholder={t('Select')}
                                                                    options={ zipcode ? zipcode :  { value: '', label: '---' } }
                                                                    value={selectedPlz}
                                                                    onChange={(e)=>{
                                                                        console.log(e);
                                                                        setValue('plz', e.label);
                                                                        setValue('ort', e.city.name);
                                                                        //setValue('ort', e.city_id);
                                                                        setSelectedPlz(e);
                                                                        //setValue('teams', JSON.stringify(e));
                                                                    }}                             
                                                                />
                                                                
                                                                {/*
                                                                <select
                                                                    className="form-control"
                                                                    {...register("plz", {
                                                                        required: true,
                                                                        onChange: (e) => {
                                                                            console.log(e.target)
                                                                            //setValue('plz', );
                                                                        }
                                                                    })}
                                                                    required
                                                                >
                                                                    <option value="">--</option>
                                                                    {zipcode ? zipcode.map((zip) => (
                                                                        <option key={zip.id} value={zip.id} city={zip.city_id}>{zip.zipcode}</option>
                                                                    )) : <></>}
                                                                </select>
                                                                */}
                                                            
                                                                <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Ort')} *</label>                                                            
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("ort", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />

                                                                {/*                                                      
                                                                <select
                                                                    className="form-control"
                                                                    {...register("ort", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                >
                                                                    <option value="">--</option>
                                                                    {city ? city.map((city_) => (
                                                                        <option key={city_.id} value={city_.id}>{city_.name}</option>
                                                                    )) : <></>}
                                                                </select>
                                                                */}      
                                                            
                                                                <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>{t('Telefonnummer')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("telefonnummer", {
                                                                        required: true,
                                                                    })}
                                                                    required
                                                                />
                                                                <div className="invalid-feedback">{errors.telefonnummer && errors.telefonnummer.message}</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-8 offset-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Entscheidungstrager')} *</label>
                                                                <input type="text" placeholder="Entscheidungstrager" className=" form-control" {...register("entscheidungstrager", {
                                                                    
                                                                    })}
                                                                    />
                                                                <div className="invalid-feedback">{errors.entscheidungstrager && errors.entscheidungstrager.message}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Erfahrung Mit PV')} *</label>
                                                                <select
                                                                    className="form-control"
                                                                    {...register("erfahrung_mit_pv", {
                                                                        
                                                                    })}
                                                                    >
                                                                    <option value="">--</option>
                                                                    <option value="Yes">{t('Yes')}</option>
                                                                    <option value="No">{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.erfahrung_mit_pv && errors.erfahrung_mit_pv.message}</div>
                                                            </div>
                                                        </div> 
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Gesamtverbrauch Kwh')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Gesamtverbrauch Kwh')}
                                                                    {...register("gesamtverbrauch_kwh", {
                                                                       
                                                                    })}
                                                                    
                                                                />
                                                                <div className="invalid-feedback">{errors.gesamtverbrauch_kwh && errors.gesamtverbrauch_kwh.message}</div>
                                                            </div>                                            
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Monatlicher Abschlag In Euro')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Monatlicher Abschlag In Euro')}
                                                                    {...register("monatlicher", {
                                                                        
                                                                    })}
                                                                    
                                                                />
                                                                <div className="invalid-feedback">{errors.monatlicher && errors.monatlicher.message}</div>
                                                            </div>                                            
                                                        </div>
                                                        
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Stromabrechung Vorhanden')} *</label>
                                                                    <select className="form-control"
                                                                        {...register("stromabrechung_vorhanden", {
                                                                            
                                                                        })}
                                                                        >
                                                                        <option>--</option>
                                                                        <option value="Yes" >{t('Yes')}</option>
                                                                        <option value="No" >{t('No')}</option>
                                                                    </select>
                                                                <div className="invalid-feedback">{errors.stromabrechung_vorhanden && errors.stromabrechung_vorhanden.message}</div>
                                                            </div>                                            
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Dachausrichtung')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('')}
                                                                    {...register("dachausrichtung", {
                                                                        
                                                                    })}
                                                                    
                                                                />
                                                                <div className="invalid-feedback">{errors.dachausrichtung && errors.dachausrichtung.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Ziegelart')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Ziegelart')}
                                                                    {...register("ziegelart", {
                                                                        
                                                                    })}
                                                                    
                                                                />
                                                                <div className="invalid-feedback">{errors.ziegelart && errors.ziegelart.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('E-Mobilitat')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('E-Mobilitat')}
                                                                    {...register("e_mobilitat", {
                                                                        
                                                                    })}
                                                                    
                                                                />
                                                                <div className="invalid-feedback">{errors.e_mobilitat && errors.e_mobilitat.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Zeitraum (max.)')} *</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={t('Zeitraum (max.)')}
                                                                    {...register("zeitraum_max", {
                                                                        
                                                                    })}
                                                                    
                                                                />
                                                                <div className="invalid-feedback">{errors.zeitraum_max && errors.zeitraum_max.message}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>{t('Internet')} *</label>
                                                                <select
                                                                    className="form-control"
                                                                    {...register("internet", {
                                                                        
                                                                    })}
                                                                    >
                                                                    <option value="">--</option>
                                                                    <option value="Yes">{t('Yes')}</option>
                                                                    <option value="No">{t('No')}</option>
                                                                </select>
                                                                <div className="invalid-feedback">{errors.internet && errors.internet.message}</div>
                                                            </div>
                                                        </div>                                                 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {Cookies.get('permissions').indexOf("project_call_center-update") !== -1 ? (
                                        <div className="row g-3">
                                            <div className="col-12 my-4">
                                                {!saving && (
                                                    <button type="submit" className="btn btn-primary" id="callSubmitBtn">
                                                        {t('Save')}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button type="submit" className="btn btn-disabled" disabled>
                                                        {t('Saving ...')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                     ) : <></>}
                                </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex mb-3">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr')}:</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {params?.id ? params?.id : '-'}                                               
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-header pb-1">
                                <h6>{t('Status')}</h6>
                            </div>
                            <div className="card-body pt-1">
                                {params?.id ? <ProjectStatus projectid={params?.id} status={data?.data?.status} /> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <Timeline refresh={refresh2} />
            </div>
            {renderSuccessSB}
        </div>
        
    </>;
}

export default CallCenter;