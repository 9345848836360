import { Card, Grid } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import callFetch from 'helpers/callFetch';
import Cookies from 'js-cookie';
import React, { useState,useRef, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-pad-wrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ACabnahmeEdit from './ACabnahmeEdit';
import Timeline from "../create/Timeline"; 
import Comisioning from "./Comisioning"; 
const ACAbnahmeprotokoll = (props) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const notify = () => toast("Success");
    const signaturePadRef = useRef(null);
    const [link,setLink] = useState(null)
    const [modules, setModules] = useState([]);
    const [id,setID] = useState(0)
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(()=>{
        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    },[0])

    useEffect(() => {
        if(props.project_id !== undefined){
            callFetch("projectdistribution/" + props.project_id + "/edit?project_id="+props.project_id, "GET", []).then((res) => {
                setValue('modultyp',res?.quation?.modul)
                setValue('modulanzahl',res?.quation?.anzahl_module)
            });

            callFetch("ac_abnahmeprotokoll/"+props.project_id+"/edit","GET",[]).then((res)=>{
                if(res?.data?.dcabname){
                    setID(res?.data?.dcabname?.id)
                }
            })
        }
    }, [props.project_id]);

    const onSubmit = (formData) => {
        formData.project_id = props.project_id
        formData.user_id = props.user_id
        callFetch("ac_abnahmeprotokoll","POST",formData,setError).then((res)=>{
            setLink(res?.data?.pdf)
            if(res?.data.message === "success"){
                notify();
                setID(res?.data?.id)
            }
        })
    }
    const clearForm = () =>{
        reset({
            id: 0,
            title: '',
            status: '',
            description: '',
            attachments: JSON.stringify([]),
        });
    }
  return (
    <>
    <div className='row'>
        <div className='col-lg-9'>
        {
        id === 0 && <form id="createOrderProcesing" className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    {
                        link !== null && <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link && link} ></a>
                    }
            <SoftBox>
                <Card sx={{ overflow: "visible" }}>
                    <SoftBox p={3}>
                        <SoftTypography variant="h5">{t('AC Abnahmeprotokoll')}</SoftTypography>
                    </SoftBox>
    
                    <SoftBox p={2} lineHeight={1}>
                        <Grid mb={2} container spacing={2}>
                            {/* <Grid item sx={12} sm={12} md={6} lg={6} >
                                    <div className="d-flex align-items-center justify-content-between mt-4" >
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >JA</label>
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("pot_schine")}
                                                        className="form-check-input me-md-12 ms-1"
                                                    />
                                                </div>
                                            </div>
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >NEIN</label>
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("erdspieß")}
                                                        required
                                                        className="form-check-input me-md-12 ms-1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={4} lg={4} >
                                <label>Name</label>
                                <input type="text" className=" form-control" placeholder="name" {...register('name',{required:true})} required />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} >
                                <label>Strasse</label>
                                <input type="text" className=" form-control" placeholder="Anlagenleistung in kwp" {...register('strasse',{required:true})} required />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} >
                                <label>Hausnummer</label>
                                <input type="number" className=" form-control" placeholder="Hausnummer" {...register('hausnummer',{required:true})} required />
                            </Grid>
                        </Grid>
    
                        <Grid my={2} container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} >
                                <label>PLZ</label>
                                <input type="number" className=" form-control" placeholder="PLZ" {...register('plz',{required:true})} required />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} >
                                <label>ORT</label>
                                <input type="text" className=" form-control" placeholder="ORT" {...register('ort',{required:true})} required />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} >
                                <label>Telefon</label>
                                <input type="number" className=" form-control" placeholder="Telefon" {...register('telefon',{required:true})} required />
                            </Grid>
                        </Grid>
    
                        <Grid my={2} container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <label>Modultyp (hersteller, typ)</label>
                                <select className="form-control" {...register('modultyp',{required:true})} required>
                                    <option>--</option>
                                        {
                                            modules && modules.map((modules,index)=>{
                                                return <option key={index} value={modules.id}>{modules.name}</option>
                                            })
                                        }
                                </select>
                            </Grid>
    
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <label>Anlagenleistung in kwp</label>
                                <input type="text" className=" form-control" placeholder="Anlagenleistung in kwp" {...register('anlagenleistung',{required:true})} required />
                            </Grid>
                        </Grid>
    
                        <Grid my={2} container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <label>Modulanzahl (parallel × reihe)</label>
                                <input type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl',{required:true})} required />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <label>Anzahl der stränge</label>
                                <input type="text" className=" form-control" placeholder="Anzahl der stränge" {...register('anzahl_der',{required:true})} required />
                            </Grid>
                            
                        </Grid>
    
                        <Grid my={2} container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <label>Wechselrichter (Hersteller, typ, nennleistung)</label>
                                <input type="text" className=" form-control" placeholder="Wechselrichter" {...register('wechselrichter',{required:true})} required />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <label>Potenzialausgleich/Erdung</label>
                                <input type="text" className=" form-control" placeholder="Potenzialausgleich/Erdung" {...register('potenzialausgleich',{required:true})} required />
                            </Grid>
                            
                        </Grid>
    
    
                        <Grid my={2} container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <label>Uberspannungsableiter DC- Und AC-Leitung</label>
                                <select className="form-control" {...register('dc_und_ac_leitung',{required:true})} required>
                                    <option>--</option>
                                    <option value="Ja" >Ja</option>
                                    <option value="Nein" >Nein</option>
                                </select>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} display="flex" alignItems="center" justifyContent="space-between" >
                                            <div className=" d-flex align-items-center mt-4">
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("general_ja_1")}
                                                        className="form-check-input"
                                                        value={1}
                                                    />
                                                </div>
                                                <label className="mt-2 " >JA</label>
                                            </div>
                                            <div className=" d-flex align-items-center mt-4">
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("general_janein_2")}
                                                        required
                                                        className="form-check-input  ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                                <label className="mt-2" >NEIN</label>
                                            </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} display="flex" alignItems="center" justifyContent="space-between">
                            <div className=" d-flex align-items-center mt-4 ms-5">
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("general_janein_3")}
                                                        className="form-check-input "
                                                        value={1}
                                                    />
                                                </div>
                                                <label className="mt-2" >JA</label>
                                            </div>
                                            <div className=" d-flex align-items-center mt-4">
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("general_janein_4")}
                                                        required
                                                        className="form-check-input  ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                                <label className="mt-2" >NEIN</label>
                                            </div>
                            </Grid>
                            
                        </Grid>
    
    
                        <Grid my={2} container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Anlagenmontage Ohne Sichtbare Schäden</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("sichtbare_in_ordnung")}
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("sichtbare_beanstandet")}
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Montagesystem, Dachdurchdringungen, Abdichtungen</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("montagesystem_in_ordnung")}
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("montagesystem_beanstandet")}
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Verkabelung, Leitungsführung</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("verkabelung_in_ordung")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("verkabelung_beanstandet")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Wechselrichter, Wechselrichterfunktion</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("wechselrichter_in_ordnung")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("wechselrichter_beanstandet")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Funktionsprüfung Der Schutzeinrichtungen</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("funktionsprüfung_in_ordnung")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("funktionsprüfung_beanstandet")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Einspeisekontrolle Am Zähler</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("einspeisekontrolle_in_ordnung")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("einspeisekontrolle_beanstandet")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px">Sauberkeit (Abfallentsorgung, Installationsorte Sauber etc.)</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("sauberkeit_in_ordnung")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("sauberkeit_beanstandet")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" alignItems="center" justifyContent="space-between">
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={6} lg={6} >
                                        <SoftTypography variant="p" fontWeight="bold" fontSize="14px" >Ausführung Entspricht Planungsumfang/Angebot</SoftTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("ausführung_in_ordnung")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >IN ORDNUNG</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3} >
                                        <div className=" d-flex align-items-center">
                                            <div className="form-check d-inline-block">
                                                <input 
                                                    type="checkbox"
                                                    {...register("ausführung_beanstandet")}
                                                    
                                                    className="form-check-input  ms-1"
                                                    value={1}
                                                />
                                            </div>
                                            <label className="mt-2 uppercase" >BEANSTANDET</label>
                                        </div>
                                        <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
                                    </Grid>
                                </Grid>
                            </Grid>
    
    
    
                        </Grid>
    
                        
                    </SoftBox>
                    
    
                    <SoftBox p={2} display="flex" justifyContent="flex-end">
                        {Cookies.get('permissions').indexOf("ac_abnahmeprotokoll-update") !== -1 ? (
                            <>
                            {!saving && (
                                <button type="submit" id="acAbname" className="btn btn-primary d-none">
                                    {t('Save')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled d-none" disabled>
                                    {t('Saving ...')}
                                </button>
                            )}
                            </>
                        ) : <></>}
                </SoftBox>
                </Card>
            </SoftBox>
        </form>
    }

    {
        id !== 0 && <ACabnahmeEdit edit_id={id} project_id={props.project_id}/>
    }
        </div>
        <div className="col-lg-3">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {props.project_id ? props.project_id : '---'}                                               
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <Comisioning projectid={props.project_id} id={id} type={'ac'}></Comisioning>
 
                <Timeline refresh={1}/>
            </div>
    </div>
    
    
</>
  )
}

export default ACAbnahmeprotokoll