import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import Setting from "examples/Icons/Setting";
import { number } from "prop-types";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";

function Sidebar() {
    const { t } = useTranslation();
    const [lastMessage, setLastMessage] = useState([]);
    const [authId, setAuthId] = useState('');
    const [nowDrop, setNowDrop] = useState(window.location.href.split('/')[3]);
    let navClassName = "nav-link";
    let activeClassName = "nav-link active";
    let dropdownClass = "collapse";
    let dropdownClassShow = "collapse show";
 
    useEffect(() => {
        callFetch("chatuser-sidebar", "GET", []).then((res) => {
            //   console.log(res.lastMessage); 
            //   console.log(res.authId); 
            setLastMessage(res.lastMessage);
            setAuthId(res.authId);
          });
    }, [])

    {/*

    {Cookies.get('permissions').indexOf("Product-update") !== -1 ? (
        <>
        
        </>
    ) : <></>}

    {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                                    <>
                                    
                                    </>
                                ) : <></>}

    */}

    //console.log(typeof JSON.parse(Cookies.get('user')).roleid)

    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3  bg-white" id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                <a className="navbar-brand m-0" href="/">
                    <img src="/assets/img/logo.png" className="navbar-brand-img h-100" style={{maxHeight: '50px', marginTop: '16px'}} alt="main_logo" />
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">

                    {JSON.parse(Cookies.get('user')).roleid == 7 || JSON.parse(Cookies.get('user')).roleid == 8 ? (
                        <>
                        
                        </>
                    ) :  <>
                        <li className="nav-item">
                            <NavLink to="/dashboard" onClick={(e) => setNowDrop('dashboard')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="sidenav-normal">{t('Dashboard')}</span>
                            </NavLink>
                        </li>
                    </>}

                    <li className="nav-item">
                        {lastMessage && (
                            <NavLink id="chat-link" to={lastMessage.sender_id == authId ? '/chat/message/'+lastMessage.receiver_id : '/chat/message/'+lastMessage.sender_id} onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-envelope icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                                <span className="nav-link-text ms-1">{t('Message')}</span>
                            </NavLink>
                        )}
                        {!lastMessage && (
                            <NavLink id="chat-link" to='/chat' onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-envelope icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                                <span className="nav-link-text ms-1">{t('Message')}</span>
                            </NavLink>
                        )}
                    </li>

                    {/* chatbot menu */}
                    <li className="nav-item">
                        <NavLink to="/chatbot" onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-envelope icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Chatbot')}</span>
                        </NavLink>
                    </li>
                    {/* chatbot menu end */}
                    
                    {Cookies.get('permissions').indexOf("Team Wise Filter Calendar") !== -1 ? (
                    <li className="nav-item"> 
                        <NavLink to='/calendar' onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-calendar icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                            <span className="nav-link-text ms-1">{t('Calendar')}</span>
                        </NavLink> 
                    </li>
                     ) : <></>}


                    <li className="nav-item">
                        <NavLink to="/support" onClick={(e) => setNowDrop('dashboard')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-tty icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Support')}</span>
                        </NavLink>
                    </li>

                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Crm')}
                    </SoftTypography>
                    {Cookies.get('permissions').indexOf("project-read") !== -1 || Cookies.get('permissions').indexOf("project-create") !== -1  ? (
                    <>
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#project-management" className={nowDrop === 'project-management' ? activeClassName : navClassName} aria-controls="project-management" role="button" aria-expanded={nowDrop === 'project-management'}>
                                <i className="fa-solid fa-briefcase icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Project Management')}</span>
                            </a>
                            <div className={nowDrop === 'project-management' ? dropdownClassShow : dropdownClass} id="project-management">
                                <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("project-create") !== -1 ? (
                                    <li className="nav-item">
                                        <NavLink to="/project-management/project/create" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('New Project')}</span>
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                {Cookies.get('permissions').indexOf("project-read") !== -1 ? (
                                    <li className="nav-item">
                                        <NavLink to="/project-management/projects" onClick={(e) => setNowDrop('project-management')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Projects')}</span>
                                        </NavLink>
                                    </li>
                                ) : <></>}
                                </ul>
                            </div>
                        </li>
                    </>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("quotation-read") !== -1 ||
                    Cookies.get('permissions').indexOf("order-read") !== -1  ? (
                    <li className={"nav-item"}>
                        <a data-bs-toggle="collapse" href="#sales-distribution" className={nowDrop === 'sales-distribution' ? activeClassName : navClassName} aria-controls="sales-distribution" role="button" aria-expanded={nowDrop === 'sales-distribution'}>
                            <i className="fa-solid fa-bag-shopping icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            {/* <svg class="icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                            <span className="nav-link-text ms-1">{t('Sales & Distribution')}</span>
                        </a>
                        <div className={nowDrop === 'sales-distribution' ? dropdownClassShow : dropdownClass} id="sales-distribution">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("quotation-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/sales-distribution/quations/view" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Angebote')}</span>
                                            </NavLink>
                                        </li>                                    
                                    </>
                                ) : <></>}
                                
                                {Cookies.get('permissions').indexOf("order-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/sales-distribution/orders" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Auftrag')}</span>
                                            </NavLink>
                                        </li>                                    
                                    </>
                                ) : <></>}

                                {Cookies.get('permissions').indexOf("customer-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/sales-distribution/customers" onClick={(e) => setNowDrop('sales-distribution')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Customer')}</span>
                                            </NavLink>
                                        </li>                                    
                                    </>
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}

                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Field Service')}
                    </SoftTypography>

                    <li className="nav-item">
                        <NavLink to="/field-service/overview" onClick={(e) => setNowDrop('field-service')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Overview')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/field-service/order-processing" onClick={(e) => setNowDrop('field-service')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Order Processing')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/field-service/leitstand" onClick={(e) => setNowDrop('field-service')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Leitstand')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/field-service/reclamations" onClick={(e) => setNowDrop('field-service')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Reclamations')}</span>
                        </NavLink>
                    </li>

                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Erp')}
                    </SoftTypography>                  
                    
                    {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#supply-chain" className={nowDrop === 'supply-chain' ? activeClassName : navClassName} aria-controls="supply-chain" role="button" aria-expanded={nowDrop === 'supply-chain'}>
                            <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Supply Chain')}</span>
                        </a>
                        <div className={nowDrop === 'supply-chain' ? dropdownClassShow : dropdownClass} id="supply-chain">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("product-read") !== -1 ? (
                                    <>
                                        <li class="nav-item ">
                                            <a class="nav-link collapsed" data-bs-toggle="collapse" aria-expanded="false" href="#productsExample">
                                                <span class="sidenav-mini-icon"> P </span>
                                                <span class="sidenav-normal"> {t('Products')} <b class="caret"></b></span>
                                            </a>
                                            <div class="collapse" id="productsExample">
                                                <ul class="nav nav-sm flex-column">
                                                    {Cookies.get('permissions').indexOf("product-create") !== -1 ? (
                                                        <>
                                                            <li className="nav-item">
                                                                <NavLink to="/supply-chain/products/create" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('New Product')}</span>
                                                                </NavLink>
                                                            </li>
                                                        </>
                                                    ) : <></>}

                                                    {Cookies.get('permissions').indexOf("module-read") !== -1 ? (
                                                        <>
                                                            <li className="nav-item">
                                                                <NavLink to="/supply-chain/products/module" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('Module')}</span>
                                                                </NavLink>
                                                            </li>
                                                        </>
                                                    ) : <></>}

                                                    {Cookies.get('permissions').indexOf("speicher-read") !== -1 ? (
                                                        <>
                                                            <li className="nav-item">
                                                                <NavLink to="/supply-chain/products/speicher" onClick={(e) => setNowDrop('supply-chain')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('Speicher')}</span>
                                                                </NavLink>
                                                            </li>
                                                        </>
                                                    ) : <></>}
                                                </ul>
                                            </div>
                                        </li>
                                    </>
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}

                    <li className="nav-item">
                        <NavLink to="/lieferanten-partner" onClick={(e) => setNowDrop('lieferanten-partner')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Lieferanten & Partner')}</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/purchase" onClick={(e) => setNowDrop('purchase')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Purchase')}</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/manufacturing" onClick={(e) => setNowDrop('manufacturing')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Manufacturing')}</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/warehouse" onClick={(e) => setNowDrop('warehouse')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-truck icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Warehouse')}</span>
                        </NavLink>
                    </li>



                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Hr')}
                    </SoftTypography>

                    {Cookies.get('permissions').indexOf("employee-read") !== -1 ? (
                    <li className="nav-item">
                        <NavLink to="/hr/employees/view" onClick={(e) => setNowDrop('employee-read')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Employees')}</span>
                        </NavLink>
                    </li>
                    ) : <></>}
                    
                    {Cookies.get('permissions').indexOf("team-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#team" className={nowDrop === 'team' ? activeClassName : navClassName} aria-controls="call-center" role="button" aria-expanded={nowDrop === 'call-center'}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Teams')}</span>
                            </a>
                            <div className={nowDrop === 'team' ? dropdownClassShow : dropdownClass} id="team">
                                <ul className="nav ms-4 ps-3">
                                    <li className="nav-item">
                                        <NavLink to="/teams/sales" onClick={(e) => setNowDrop('sales-team')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Sales Team')}</span>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/teams/ac" onClick={(e) => setNowDrop('ac-team')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('AC Team')}</span>
                                        </NavLink>
                                    </li> 

                                    <li className="nav-item">
                                        <NavLink to="/teams/dc" onClick={(e) => setNowDrop('dc-team')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('DC Team')}</span>
                                        </NavLink>
                                    </li>  
                                    
                                </ul>
                            </div>
                        </li> 
                    ) : <></>}

                    <li className="nav-item">
                        <NavLink to="/holiday" onClick={(e) => setNowDrop('holiday')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Holiday')}</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink to="/akademie" onClick={(e) => setNowDrop('akademie')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Akademie')}</span>
                        </NavLink>
                    </li>
                   

                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Finanzen')}
                    </SoftTypography>

                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                        <li className="nav-item">
                            <a data-bs-toggle="collapse" href="#invoice" className={nowDrop === 'invoice' ? activeClassName : navClassName} aria-controls="call-center" role="button" aria-expanded={nowDrop === 'call-center'}>
                                <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Invoices')}</span>
                            </a>
                            <div className={nowDrop === 'invoice' ? dropdownClassShow : dropdownClass} id="invoice">
                                <ul className="nav ms-4 ps-3">
                                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                                    <li className="nav-item">
                                        <NavLink to="/finance/invoices" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                            <span className="sidenav-normal">{t('Invoices')}</span>
                                        </NavLink>
                                    </li>   
                                    ) : <></>}
                                </ul>
                            </div>
                        </li>
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("invoice-read") !== -1 ? (
                        <li className="nav-item">
                            <NavLink to="finance/payments" onClick={(e) => setNowDrop('payments')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Payments')}</span>
                            </NavLink>
                        </li> 
                    ) : <></>}

                    {Cookies.get('permissions').indexOf("payroll-read") !== -1 ? (
                        <li className="nav-item">
                            <NavLink to="/finance/payrolls" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                <span className="nav-link-text ms-1">{t('Payrolls')}</span>
                            </NavLink>
                        </li>
                    ) : <></>}

                    <li className="nav-item">
                        <NavLink to="/finance/artikels" onClick={(e) => setNowDrop('invoices')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-coins icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Artikel')}</span>
                        </NavLink>
                    </li>
                    

                    <Divider />                    
                    <SoftTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        opacity={0.6}
                        pl={4}
                        mt={0}
                        mb={1}
                        ml={1}
                    >
                        {t('Einstellungen')}
                    </SoftTypography>
                    
                    {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                    <li className="nav-item">
                        <a data-bs-toggle="collapse" href="#user-settings" className={nowDrop === 'user-settings' ? activeClassName : navClassName} aria-controls="user-settings" role="button" aria-expanded={nowDrop === 'user-settings'}>
                            <i className="fa-solid fa-gear icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="nav-link-text ms-1">{t('Settings')}</span>
                        </a>
                        <div className={nowDrop === 'user-settings' ? dropdownClassShow : dropdownClass} id="user-settings">
                            <ul className="nav ms-4 ps-3">
                                {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="/settings/roles" onClick={(e) => setNowDrop('user-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('Settings')}</span>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <a data-bs-toggle="collapse" href="#email-settings" className={nowDrop === 'email-settings' ? activeClassName : navClassName} aria-controls="email-settings" role="button" aria-expanded={nowDrop === 'email-settings'}>
                                               <span className="nav-link-text">{t('Email Setup')}</span>
                                            </a>
                                            <div className={nowDrop === 'email-settings' ? dropdownClassShow : dropdownClass} id="email-settings">
                                                <ul className="nav ms-3 ps-1                                                                  ">
                                                    {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                                        <>
                                                            <li className="nav-item">
                                                                <NavLink to="/settings/smtp/1/edit" onClick={(e) => setNowDrop('smptp-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                    <span className="sidenav-normal">{t('SMTP')}</span>
                                                                </NavLink>
                                                            </li>

                                                            <li className="nav-item">
                                                                <a data-bs-toggle="collapse" href="#emails-settings" className={nowDrop === 'email-settings' ? activeClassName : navClassName} aria-controls="emails-settings" role="button" aria-expanded={nowDrop === 'emails-settings'}>
                                                                    <span className="nav-link-text ms-1">{t('Emails')}</span>
                                                                </a>
                                                                <div className={nowDrop === 'emails-settings' ? dropdownClassShow : dropdownClass} id="emails-settings">
                                                                    <ul className="nav ms-1 ps-1">
                                                                        {Cookies.get('permissions').indexOf("role-read") !== -1 ? (
                                                                            <>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/angebot/1/edit" onClick={(e) => setNowDrop('angebot-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('Angebot')}</span></NavLink></li>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/order/1/edit" onClick={(e) => setNowDrop('order-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('Order')}</span></NavLink></li>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/ac-planning/1/edit" onClick={(e) => setNowDrop('acPlanung-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('AC Planung')}</span></NavLink></li>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/dc-planning/1/edit" onClick={(e) => setNowDrop('dcPlanung-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('DC Planung')}</span></NavLink></li>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/70-percent-invoice/1/edit" onClick={(e) => setNowDrop('70_percent_invoice-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('70% ')+t('Invoice')}</span></NavLink></li>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/30-percent-invoice/1/edit" onClick={(e) => setNowDrop('30_percent_invoice-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('30% '+t('Invoice'))}</span></NavLink></li>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/70-percent-payment/1/edit" onClick={(e) => setNowDrop('70_percent_payment-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('70% '+t('Payment'))}</span></NavLink></li>
                                                                                <li className="nav-item"><NavLink to="/settings/emails/30-percent-payment/1/edit" onClick={(e) => setNowDrop('30_percent_payment-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}><span className="sidenav-normal">{'- '+t('30% '+t('Payment'))}</span></NavLink></li>
                                                                            </>
                                                                        ) : <></>}
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </>
                                                    ) : <></>}
                                                </ul>
                                            </div>
                                        </li>
                                    </>
                                ) : <></>}
                            </ul>
                        </div>
                    </li>
                    ) : <></>}
                                       
                    <li className="nav-item d-none">
                        <NavLink to="/application-settings" onClick={(e) => setNowDrop('')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa fa-globe icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center"></i>
                            <span className="nav-link-text ms-1">Application Settings</span>
                        </NavLink>
                    </li>

                    
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
