import React, { useState,useRef, useEffect } from 'react'
import { useParams } from "react-router-dom";
import Cookies from 'js-cookie';

function RoofPlannerV2(props) {
  let params = useParams();

  return (
    <>
    <div className='row'>
        <div className='col-md-12'>
            <div className="card">
                <div className="card-body p-3">
                    <iframe src={Cookies.get('permissions').indexOf("roofplanner-create") !== -1 ? process.env.REACT_APP_BACKEND_URL+'roofplan/'+params?.id+'?token='+Cookies.get('token') : process.env.REACT_APP_BACKEND_URL+'roofplan/show/'+params?.id+'?token='+Cookies.get('token') } style={{width: '100%', height: '850px'}} />
                </div>
            </div>
        </div>
    </div>     
    </>
  )
}

export default RoofPlannerV2