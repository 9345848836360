import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import callFetch from "helpers/callFetch";
import Cookies from 'js-cookie';

export default function Chat() {

    const [historyFiles, setHistoryFiles]  = useState([]);
    const [user]                           = useState(JSON.parse(Cookies.get('user')));
    const [conversation, setConversation]  = useState([]);
    const [history,setHistory]             = useState('');
    const [questionAsked,setQuestionAsked] = useState(false);
    const [loading,setLoading]             = useState(false);
    const location                         = useLocation();
    const navigate                         = useNavigate();


    const dateDiff = ( date ) => {

        var now      = new Date();
        date         = typeof date == 'object' ? date : new Date(date);

        var diffInMs = Math.abs(now.getTime() - date.getTime());

        if (diffInMs < 1000 * 60) { // less than a minute
            return "just now";
        } else if (diffInMs < 1000 * 60 * 60) { // less than an hour
            var diffInMin = Math.floor(diffInMs / (1000 * 60));
            return diffInMin + " minute" + (diffInMin == 1 ? "" : "s") + " ago";
        } else if (diffInMs < 1000 * 60 * 60 * 24) { // less than a day
            var diffInHr = Math.floor(diffInMs / (1000 * 60 * 60));
            return diffInHr + " hour" + (diffInHr == 1 ? "" : "s") + " ago";
        } else if (diffInMs < 1000 * 60 * 60 * 24 * 7) { // less than a week
            var diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
            return diffInDays + " day" + (diffInDays == 1 ? "" : "s") + " ago";
        } else if (diffInMs < 1000 * 60 * 60 * 24 * 30) { // less than a month
            var diffInWeeks = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 7));
            return diffInWeeks + " week" + (diffInWeeks == 1 ? "" : "s") + " ago";
        } else if (diffInMs < 1000 * 60 * 60 * 24 * 365) { // less than a year
            var diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
            return diffInMonths + " month" + (diffInMonths == 1 ? "" : "s") + " ago";
        } else { // more than a year
            var diffInYears = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365));
            return diffInYears + " year" + (diffInYears == 1 ? "" : "s") + " ago";
        }
    }

    const getHistoryFiles = () => {
        //request for getting history files
        callFetch('history-files','GET',[]).then(res => {
            setHistoryFiles(res);
        });
    }

    if ( questionAsked ) {
        getHistoryFiles();
        setQuestionAsked(false);
    }
 
    useEffect(() => {
       
        const searchParams = new URLSearchParams(location.search);

        if ( searchParams.has('history') ) {
            const filename = searchParams.get('history');
            setLoading(true);
            setConversation([]);

            //set history
            setHistory(filename);

            callFetch(`get-chat-history/${filename}`,'GET',[]).then(res => {
                setConversation(res);
                setLoading(false);
            });
        } else if ( searchParams.has('clear') ) {

            const action = searchParams.get('clear');
            setLoading(true);

            callFetch(`clear-history/${action}`,'GET',[]).then(res => {
                setConversation([]);
                setLoading(false);
                setQuestionAsked(false);
                setHistoryFiles([]);
                navigate('/chatbot');
            });

        } else {

            setLoading(false);
            setQuestionAsked(false);
            setHistory('');
            navigate('/chatbot?model=turbo-3.5')
            setConversation([]);

        }

        getHistoryFiles();

    },[location.search]);

    const sendMessage = (e) => {

        // select text box
        const text          = document.querySelector('#text');
        const history       = document.querySelector('#history');
        const prompt        = text.value;
        if (text.value == "") return;

        const d = new Date();
        
        e.target.setAttribute('disabled',true);
        e.target.classList.add('writting');

        const test = {
            'text' : text.value,
            'type' : 'question',
            'time' : new Date()
        }

        setConversation([...conversation,test]);

        text.value = '';

        callFetch("send-message", "POST",{
            text      : prompt,
            history   : history.value,
            timezone  : Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        .then((res) => {
            
            let content  = res.data.choices[0].message.content;

            if ( typeof content == 'undefined' ) {
                content = 'Message is not sent due to problem';
            }
            
            setConversation([...conversation,test,{
                'text' : content,
                'type' : 'answer',
                'time' : new Date()
            },]);

            setHistory(res.history);

            e.target.classList.remove('writting');
            e.target.removeAttribute('disabled');

            // set questionAsked true
            setQuestionAsked(true);
        }); 

    }  

    document.onkeydown = (e) => {
        if (e.keyCode == 13) {
            document.querySelector('.send').click();
        }
    }

    const addToggleClass = () => {
        document.querySelector('.window-area').classList.toggle('active-chatbot-sidbar')
    }

    return (
        <>
            <div className="window-wrapper" >
                <div className="window-area">
                    <div className="top-chatbot">
                        <div className="collapse-bar" onClick={e => addToggleClass()}>
                            <i className="fa-solid fa-bars"></i>
                            <i className="fa-solid fa-x"></i>
                        </div>
                        <div className="history-chat">
                            New Chat
                        </div>
                        <div className="add-new-chat">
                            <NavLink id="chat-link" to='/chatbot'>
                                <i className="fa-solid fa-plus"></i>
                            </NavLink>
                        </div>
                    </div>
                    <div className="conversation-list">
                        <ul className="list-history">
                            <li>
                                <NavLink id="chat-link" to='/chatbot'>
                                    <i className="fa-solid fa-plus"></i>New Chat
                                </NavLink>
                            </li>
                            {
                                historyFiles.map((file,index) => {
                                    const basename = file.split('/').pop().split('.')[0];
                                    const route = `/chatbot?history=${basename}`;
                                    const singleDelete = `/chatbot?clear=${basename}`;

                                    return (
                                        <li key={index}>
                                            <NavLink id="chat-link" to={route} className={basename == history ? 'active-history' : ''}>
                                                {
                                                    basename.replaceAll('-',' ')
                                                }
                                            </NavLink>
                                            <NavLink className="single-delete" id="chat-link" to={singleDelete}>
                                                <i className="fa fa-trash delete-single" ></i>
                                            </NavLink>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="my-account">
                            { historyFiles.length > 0 && <div className="clear-history">
                                <NavLink to="/chatbot?clear=history">
                                    Clear conversation&nbsp;
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </NavLink>
                            </div>}
                            <div className="image">
                                <img src="https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg" />
                                <i className="fa fa-circle online"></i>
                            </div>
                            <div className="name">
                                <span>{user && user.name}</span>
                                <i className="fa fa-angle-down"></i>
                                <span className="availability">Available</span>
                            </div>
                        </div>
                    </div>
                    <div className="chat-area">
                        <div className="chat-list">
                            {conversation.length == 0 ? <h2 className="nothing">
                                {loading && 'loading...'}
                                {! loading && 'Start New Conversation'}
                            </h2> : ''}
                            { conversation.length > 0 && <ul id="conversation">
                                {conversation.map((conv,index) => {
                                    const userImage = conv.type == 'question' ? 'https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg' : '/assets/img/logo.png';
                                    return (
                                        <li className={ conv.type == 'question' ? 'me' : '' } key={index}>
                                            <div className="wrap">
                                                <div className="name">
                                                    <img src={userImage} alt="" />
                                                </div>
                                                <div className="message">
                                                    <pre>
                                                        {conv.text}
                                                    </pre>
                                                    <span className="msg-time">
                                                        {dateDiff(conv.time)}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>}
                        </div>
                        <div className="input-area">
                            <div className="input-wrapper">
                                <input type="text" name="text" placeholder="Send Message Here" id="text" />
                                <input type="hidden" name="history" value={history} id="history"/>

                                <button className="send" onClick={(e) => sendMessage(e)}>
                                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                    <div className="loader-wr">
                                        <img src="https://i.ibb.co/7X1L7mb/ezgif-com-crop-1.gif" />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}