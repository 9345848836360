import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'; 
import flatpickr from "flatpickr"; 

import SoftBox from "components/SoftBox"; 
import Grid from "@mui/material/Grid"; 

// @mui material components
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Timeline from "../Timeline";
import PDF from "./PDF";

import { NumericFormat } from 'react-number-format';
import SignaturePad from 'react-signature-pad-wrapper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import callFetch from "helpers/callFetch";
import ACPDF from "./ACPDF";

const ACabnahmeSign = (props,{project_id}) => {
    const { t } = useTranslation(); 
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showPadClient, setShowPadClient] = useState(true);
    const [showPad, setShowPad] = useState(true);
    const [submitSuccess, setSubmitSuccess] = useState(false); 
    const signaturePadRef = useRef(null);
    const signaturePadRefClient = useRef(null); 
    const [activeStep, setActiveStep] = useState(2);
    const [data, setData] = useState([]);
    const [quationId, setQuationId] = useState(props.quationId);
    const [playAnimation, setPlayAnimation] = useState(true);
    const {
        reset, resetField, register, handleSubmit, setError, setValue, getValues,
        formState: { errors },
      } = useForm();
    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("ac_abnahmeprotokoll/"+props.project_id+"/edit","GET",[]).then((res)=>{
            setData(res.data)
            if(res.data?.sign?.elektriker_sign !== null || res.data?.sign?.client_sign !== null){
                signaturePadRef.current.fromDataURL(res.data?.sign?.elektriker_sign);
                signaturePadRefClient.current.fromDataURL(res.data?.sign?.client_sign);
            }
            
        })
        // callFetch("project/order/" + props.id + "/edit?quationId="+quationId+"&projectid="+props.projectid, "GET", []).then((res) => {
        //     setData(res.data); 
        //     if(res.data && res.data.order){
        //         signaturePadRef.current.fromDataURL(res.data.order.sign_draw);
        //         signaturePadRefClient.current.fromDataURL(res.data.order.client_sign_draw);
        //     }  
        //     setValue('i', 0); 
        // });
    }, [refresh]);

    useEffect(()=>{
        
    },[refresh])

    const hideSign = () => {
        setShowPad(true);
    }
    
    const hideSignClient = () => {
        setShowPadClient(true);
    } 

    useEffect(() => { // useEffect hook
        if(playAnimation){
            //document.body.classList.add('loading');
        } 
          setTimeout(() => { // simulate a delay
             setPlayAnimation(false)
            //document.body.classList.remove('loading');
           }, 10000);
    }, [refresh]);
   
 
    const onSubmit = (formData) => { 
        setSaving(true);
        if(signaturePadRef.current.isEmpty() && signaturePadRefClient.current.isEmpty()){
            alert('Sign please');
            return;
        }
        formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.client_sign = signaturePadRefClient.current.toDataURL('image/png')
        formData.id = data?.dcabname?.id;
        callFetch("acsign", "POST", formData, setError).then((res) => {
                setRefresh(refresh + 1);
                setPlayAnimation(true); 
                setSaving(false);
        });  
    }
    return submitSuccess ? <Navigate to='/sales-distribution/orders' /> :
    <> 
        
        <div className="row">
            <div className="col-lg-12 mt-lg-0" style={{position: 'relative'}}>
                {/* {playAnimation ? <div className="loader-pdf"><p>Loading...</p></div> : ''} */}
                
               

                <div className={playAnimation ? 'card mb-4 loading' : "card mb-4"}>
                    <div className="card-body"> 
                         <ACPDF playAnimation={playAnimation} data={data} refresh={refresh+1} ></ACPDF>
                        <div className="row">
                            <div className="col-sm-6">
                                {!showPad ? <div>
                                    <div className="sign-pad"> 
                                        <img style={{width: '100%'}} src={data && data.order ? process.env.REACT_APP_STORAGE_URL+'signs/'+data.order.sign : ''} alt=""/>                                    
                                        <p style={{borderTop: '1px solid #000', textAlign: 'center'}}>Unterschrift Kunde</p> 
                                    </div> <button type="button" className="btn btn-outline-dark"  onClick={() => { hideSign(); }}>
                                        <i className="fa fa-eraser"></i>
                                    </button>
                                </div>: ''}
                                {showPad ?<div><div className="sign-pad">
                                     <SignaturePad options={{minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)'}} ref={signaturePadRef}/>
                                    <p style={{borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px'}}>Unterschrift Kunde</p>  
                                </div>
                                <button type="button" className="btn btn-outline-dark"  onClick={() => { signaturePadRef.current.clear(); }}>
                                     <i className="fa fa-eraser"></i>
                                </button>   </div>  : ''}
                                
                            </div>
                            <div className="col-sm-6"> 
                                {!showPadClient ? <div><div className="sign-pad">
                                    <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{position: 'relative', top: '140px'}}></i>
                                    <img style={{width: '100%'}} src={data && data.order ? process.env.REACT_APP_STORAGE_URL+'signs/'+data.order.client_sign : ''} alt=""/>                                    
                                    <p style={{borderTop: '1px solid #000', textAlign: 'center'}}>Unterschrift Elektriker</p> 
                                </div> <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                    <i className="fa fa-eraser"></i>
                                </button></div> : ' '}
                                {showPadClient ? <div><div className="sign-pad-new">
                                    <SignaturePad options={{minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)'}} ref={signaturePadRefClient}/>
                                    <p style={{borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px'}}>Unterschrift Elektriker</p> 
                                </div>
                                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                    <i className="fa fa-eraser"></i>
                                </button>  </div> : ''}
                            </div> 
                        </div>
                    </div> 
                </div>
                <form onSubmit={handleSubmit(onSubmit)}> 
                <div className="row"> 
                        <div className="col-sm-4">
                                        {!saving && (
                                            <button type="submit" id="saveSign" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                            
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving ...')}
                                            </button>
                                        )}   
                        </div> 
                </div>
                </form> 
            </div>
            
        </div>
        <ToastContainer />
        </>
    ;
}

export default ACabnahmeSign