import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "../../../helpers/callFetch";
import flatpickr from "flatpickr";
import Moment from 'react-moment';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftEditor from "components/SoftEditor";
import SoftDropzone from "components/SoftDropzone";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";
import team4 from "assets/images/team-4.jpg";
import Icon from "@mui/material/Icon";
import SoftBadge from "components/SoftBadge";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";


import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderProcessing from "../create/OrderProcessing";
import SkizzeModulbelegung from "./SkizzeModulbelegung";
import SignaturePad from 'react-signature-pad-wrapper';
import DCabnahmeEdit from "./DCabnahmeEdit";
import Timeline from "../create/Timeline"; 
import SoftSnackbar from "components/SoftSnackbar";
import Comisioning from "./Comisioning"; 

const DCAbnahmeprotokoll = (props) => {
    const { t } = useTranslation();
    const notify = () => toast("Success");
    const errorNotify = () => toast("Fill Out Skizze/Modulbelegung");
    const errorNotifys = () => toast("Please Fill The Form");
    const params = useParams()
    const user = JSON.parse(Cookies.get('user'));
    const signaturePadRef = useRef(null);
    const submitBtn = useRef();
    const [saving, setSaving] = useState(false);
    const [modules, setModules] = useState([]);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay,setEditDelay] = useState(0)
    const [refresh2, setRefresh2] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [description, setDescription] = useState("");
    const [attachments, setAttachment] = useState([]);
    const [data, setData] = useState([]);
    const [link,setLink] = useState(null)
    const [id,setID] = useState(0)
    const[newDropzone, setNewDropzone] = useState(true);
    const[editForm, setEditForm] = useState(false);
  
    const {
      register,
      handleSubmit,
      setError,
      setValue,
      getValues,
      reset,
      formState: { errors },
  } = useForm();

    useEffect(()=>{
        callFetch("module/all", "GET", []).then((res) => {
            setModules(res.data);
        });
    },[0])

    useEffect(() => {
        if(props.project_id !== undefined){
            callFetch("projectdistribution/" + props.project_id + "/edit?project_id="+props.project_id, "GET", []).then((res) => {
                setValue('modultyp',res?.quation?.modul)
                setValue('modulanzahl',res?.quation?.anzahl_module)
                setEditDelay(1)
            });

            callFetch("dc_abnahmeprotokoll/"+props.project_id+"/edit","GET",[]).then((res)=>{
                if(res?.data?.dcabname){
                    setID(res?.data?.dcabname?.id)
                }
                
            })

        }
        
    }, [refresh]);

    

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Changes saved successfully"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );

    const onSubmit = (formData) => {
    
      formData.project_id = props.project_id;
      formData.user_id = props.user_id
      formData.description = description;
      formData.eidt_id = Cookies.get('dc_pdf');
      formData.sign = signaturePadRef.current.toDataURL('image/png');
    //   Cookies.remove('dc_pdf')
      if(signaturePadRef.current.isEmpty()){
        errorNotify()
        return;
      }  
      callFetch("dc_abnahmeprotokoll", "POST", formData, setError).then((res) => {
        openSuccessSB(); 
        setData(res);
          setLink(res?.data?.pdf)
          setID(res?.data?.id)
          if (!res.ok) return;
          setSubmitSuccess(true);
          setRefresh(1)
          clearForm();
          notify();
    });
  };
  const clearForm = () =>{
    reset({
        id: 0,
        title: '',
        status: '',
        description: '',
        attachments: JSON.stringify([]),
    });
  
    setNewDropzone(true);
    setEditForm(false);
    setAttachment([]);
    setDescription("");
  }



    return (

        <>
        <div className="row">
            <div className="col-lg-9">
            {
                id === 0 &&         <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <SoftBox>
                    {
                        link !== null && <a className="opacity-0" target={"_blank"} id="dc_abnahmeprotokoll" href={link && link} ></a>
                    }
                    
                    <input type="hidden" id="dcid" value={id} />
                    <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t('DC Abnahmeprotokoll')}</SoftTypography>
                        </SoftBox>
                        <SoftBox p={2} lineHeight={1}>
                        <Grid mb={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 1</label>
                                    <input type="text" className=" form-control" placeholder="Strg 1" {...register('strg_1',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_1 && errors.strg_1.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 2</label>
                                    <input type="text" className=" form-control" placeholder="Strg 2" {...register('strg_2',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_2 && errors.strg_2.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 3</label>
                                    <input type="text" className=" form-control" placeholder="Strg 3" {...register('strg_3',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_3 && errors.strg_3.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Strg 4</label>
                                    <input type="text" className=" form-control" placeholder="Strg 4" {...register('strg_4',{required: true})} required />
                                    <div className="invalid-feedback">{errors.strg_4 && errors.strg_4.message}</div>
                                </Grid>
                        </Grid>
    
    
                        {/* str start from harea */}
    
                        <Grid mb={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 1</label>
                                    <input type="text" className=" form-control" placeholder="Str 1" {...register('str_1',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_1 && errors.str_1.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 2</label>
                                    <input type="text" className=" form-control" placeholder="Str 2" {...register('str_2',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_2 && errors.str_2.message}</div>
    
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 3</label>
                                    <input type="text" className=" form-control" placeholder="Str 3" {...register('str_3',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_3 && errors.str_3.message}</div>
    
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Str 4</label>
                                    <input type="text" className=" form-control" placeholder="Str4" {...register('str_4',{required: true})} required />
                                    <div className="invalid-feedback">{errors.str_4 && errors.str_4.message}</div>
                                </Grid>
                        </Grid>
                        {/* <SoftBox pl={3} mb={2} >
                            <SoftTypography variant="h6">{t('Anlagendaten')}</SoftTypography>
                        </SoftBox> */}
                        <Grid my={2} container  spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Modultyp</label>
                                    <select type="text" className=" form-control" {...register('modultyp',{required: true})} required>
                                        <option>--</option>
                                        {
                                            modules && modules.map((modules,index)=>{
                                                return <option key={index} value={modules.id}>{modules.name}</option>
                                            })
                                        }
                                        
                                    </select>
                                    <div className="invalid-feedback">{errors.modultyp && errors.modultyp.message}</div>
                                </Grid>
    
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Modulanzahl</label>
                                    <input type="number" className=" form-control" placeholder="Modulanzahl" {...register('modulanzahl',{required: true})} required />
                                    <div className="invalid-feedback">{errors.modulanzahl && errors.modulanzahl.message}</div>
                                </Grid>
                        </Grid>
    
    
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Stecker System</label>
                                    <input type="text" className=" form-control" placeholder="Stecker System" {...register('stecker_system',{required: true})} required />
                                    <div className="invalid-feedback">{errors.stecker_system && errors.stecker_system.message}</div>
    
                                </Grid>
    
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Montagesystem</label>
                                    <input type="text" className=" form-control" placeholder="Montagesystem" {...register('montagesystem',{required: true})} required />
                                    <div className="invalid-feedback">{errors.montagesystem && errors.montagesystem.message}</div>
    
                                </Grid>
                        </Grid>
    
    
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Erdungsanschluss</label>
                                    {/* <input type="text" className=" form-control" placeholder="Erdungsanschluss" {...register('erdungsanschluss',{required: true})} required /> */}
                                        <div className="d-flex align-items-center justify-content-between mt-2" >
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >Pot.schine</label>
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("pot_schine")}
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >Erdspieß</label>
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("erdspieß")}
                                                        required
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="invalid-feedback">{errors.erdungsanschluss && errors.erdungsanschluss.message}</div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an der PV Anlage</label>
                                    <select className=" form-control" {...register('pv_system',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.pv_system && errors.pv_system.message}</div>
    
                                </Grid>
                        </Grid>
    
    
                        
                        <Grid my={2} container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                 <label>Sichtbare Schäden am Dach</label>
                                    <select className=" form-control" {...register('the_roof',{required: true})} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>      
                                    <div className="invalid-feedback">{errors.the_roof && errors.the_roof.message}</div>
    
                        </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Gebäude</label>
                                    <select className=" form-control" {...register('to_build',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.to_build && errors.to_build.message}</div>
    
                                </Grid>
                        </Grid>
        
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Spannungsprotokoll erstellt</label>
                                    <select className=" form-control" {...register('log_created',{required: true})} >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.log_created && errors.log_created.message}</div>
    
                                </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an Verkablung, Stecker</label>
                                    <select className=" form-control" {...register('wiring_plugs',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.wiring_plugs && errors.wiring_plugs.message}</div>
    
                                </Grid>
                        </Grid>
        
                        <Grid my={2} container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden an Leitungsführung</label>
                                    
                                    <select className=" form-control" {...register('to_the_wiring',{required: true})} require>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.to_the_wiring && errors.to_the_wiring.message}</div>
    
                                </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Montagesystem</label>
                                    <select className=" form-control" {...register('mounting_system',{required: true})} required >
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
                        </Grid>
        
                        <Grid my={2} container spacing={2} >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Sichtbare Schäden am Wechselrichter</label>
                                    <select className=" form-control" {...register('the_inverter',{required: true})} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
        
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Baustelle sauber hinterlassen</label>
                                    <select className=" form-control" {...register('site_clean',{required: true})} required>
                                        <option>----</option>
                                        <option value="Ja" >Ja</option>
                                        <option value="Nein" >Nein</option>
                                    </select>
                                </Grid>
                        </Grid>                
    
    
                        <Grid my={2} container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="center" >
                                <div className="form-check d-inline-block">
                                    <input 
                                        type="checkbox"
                                        {...register("leistungsoptim_check")}
                                        required
                                        className="form-check-input"
                                        value={1}
                                    />
                                </div>
                                <label className="mt-2" >Leistungsoptim</label>
                                <input className=" form-control ms-2" {...register("leistungsoptim",{
                                    required:true
                                })} required/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} display="flex" alignItems="center" >
                                <label>Stk</label>
                                <input className=" form-control ms-2" placeholder="Stk" {...register("stk",{
                                    required:true
                                })} required />
                            </Grid>
                        </Grid>
    
    
                            <Grid container mt={1} spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                     <div className="d-flex align-items-center justify-content-between mt-4" >
                                            <label>Verkablung / Leitungsführung:</label>
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >In Ordnung</label>
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("in_ordnung")}
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                        >
                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Beanstandet')}</SoftTypography>
                                        </SoftBox>
                                        <SoftInput 
                                            placeholder={t('Beanstandet')}
                                            {...register("beanstandet", {
                                                required: true,
                                            })}
                                            required
                                        />
        
                                    </SoftBox>
                                </Grid>
                            </Grid>
        
                            <Grid container spacing={2} my={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                     <div className="d-flex align-items-center justify-content-between mt-4" >
                                            <label>Asführung entspricht dem Angebot:</label>
                                            <div className=" d-flex align-items-center">
                                                <label className="mt-2" >In Ordnung</label>
                                                <div className="form-check d-inline-block">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("in_ordnung2")}
                                                        className="form-check-input me-md-12 ms-1"
                                                        value={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                        >
                                        <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">{t('Beanstandet')}</SoftTypography>
                                        </SoftBox>
                                        <SoftInput 
                                            placeholder={t('Beanstandet')}
                                            {...register("beanstandet2", {
                                                required: true,
                                            })}
                                            required
                                        />
        
                                    </SoftBox>
                                </Grid>
                            </Grid>
                            
        
                                            
        
                            <SoftBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                                >
                                <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                        {t('Description')}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftEditor
                                    value={description}
                                    onChange={setDescription}
                                />
                            </SoftBox>
    
                            
    
                            
                        </SoftBox>
                        {Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-update") !== -1 ? (
                        <>
                        <SoftBox  p={2} display="flex" justifyContent="flex-end">
                            <a href="#" className="btn btn-danger mx-2 d-none" onClick={()=>{ clearForm() }}>
                                {t('Cancel')}
                            </a>
                            {!saving && (
                                <button  id="dcAbname" type="submit" className="btn btn-primary d-none">
                                    {t('Save')}
                                </button>
                            )}
                            {saving && (
                                <button type="submit" className="btn btn-disabled d-none" disabled>
                                    {t('Saving ...')}
                                </button>
                            )}
                        </SoftBox>
                         </>
                         ) : <></>}
                    </Card>
                </SoftBox>
                {Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-update") !== -1 ? (
                <SoftBox my={2}>
                    <Card sx={{ overflow: "visible" }}>
                        <SoftBox p={3}>
                            <SoftTypography variant="h5">{t(' Skizze/Modulbelegung')}</SoftTypography>
                        </SoftBox>
    
                        <SignaturePad options={{minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)'}} ref={signaturePadRef} />
                        
                        <Grid container px={2}>
                            <Grid item sx={12} md={12} display="flex" justifyContent="center">
                                <button type="button" className="btn btn-outline-dark"  onClick={() => { signaturePadRef.current.clear(); }}>
                                    <i className="fa fa-eraser"></i>
                                </button> 
                            </Grid>
                        </Grid>
    
                    </Card>
                </SoftBox>
                 ) : <></>}
            </form>
            }
            {
                id !== 0 && <DCabnahmeEdit edit_id={id} project_id={props.project_id}/>
            }
            </div>
            <div className="col-lg-3">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Project Nr.')}</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {props.project_id ? props.project_id : '---'}                                               
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <Comisioning projectid={props.project_id} id={id} type={'dc'}></Comisioning>
 
                <Timeline refresh={1}/>
            </div>
            {renderSuccessSB}
        </div> 
        </>
      )
    }

export default DCAbnahmeprotokoll