import { React, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import { NumericFormat } from 'react-number-format';
import dateFormat from "dateformat";
import SoftTypography from "components/SoftTypography";
import { Navigate } from "react-router-dom";
import SoftBadgeDot from "components/SoftBadgeDot";

function IndexTable() {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey,setSearchKey] = useState("")
    const [searchData,setSearchData] = useState([])
    const [filterData,setFilterData] = useState([])

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [orderId, setOrderId] = useState(0);

    const createOrder = (quation_id, gesami_netto, tax, gesami_brutto) => {
        setSaving(true);
        callFetch("order", "POST", {
            quation_id: quation_id,
            currency: 'EUR',
            netto: gesami_netto,
            tax: tax,
            brutto: gesami_brutto,
        }, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setOrderId(res.data.id);
            setSubmitSuccess(true);
        });
    };

    const tableHeadings = [
        {
            name: t('ID'),
            selector: row => <NavLink to={'/sales-distribution/quations/' + row.id + '/edit'}> {row.id}</NavLink>,
            sortable: true,
        },

        {
            name: t('Verkaufer'),
            cell: row => (row.user && row.user.name ? row.user.name : ''),
        },
     
        {
            name: t('Date'),
            selector: row => dateFormat(row.created_at, "dd.mm.yyyy"),
        },

        {
            name: t('Status'),
            cell: row => (
                <>
                    <span style={{height: '0.375rem', width: '0.375rem', backgroundColor: row.status == '1-Verkauft' ?
                                '#21d4fd' : row.status == '2-Kontte nicht verkauft werden' ? 
                                '#ff0080' : row.status == '3-Angebot' ? 
                                '#fbcf33' : row.status == '4-Überleger' ? 
                                '#e6980b' : row.status == '5-Kunde war nicht da' ? 
                                '#9320bb' : row.status == '6-Kein Interesse / Keine Beratung' ? 
                                '#9320bb' : row.status == '7-Technisch nicht möglich' ? 
                                '#9320bb' : row.status == '0-Zeitlich nicht geschaft' ? 
                                '#9320bb' : 'dark', borderRadius: '50%', display: 'inline-block'}}></span>
                    <SoftTypography style={{position: 'relative', top: '1px', left: '3px', fontSize: '0.75rem', display: 'inline'}}>{row.status}</SoftTypography>
                    
                </>
            )
        },
       
        {
            name: t('Price'),
            width: '120px',
            cell: row => <NumericFormat 
            value={Number(row.gesami_netto)} 
            displayType="text" 
            thousandSeparator={"."} 
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            suffix={' € '}
        />,
        },
       
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {Cookies.get('permissions').indexOf("quotation-update") !== -1 ||
                    Cookies.get('permissions').indexOf("order-create") !== -1 ||
                    Cookies.get('permissions').indexOf("quotation-delete") !== -1 ? (
                        <div className="text-center dropstart">
                        <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-ellipsis-v text-xs"></i>
                        </a>
                        <ul className="dropdown-menu">
                            {Cookies.get('permissions').indexOf("order-create") !== -1 ? (
                                <>
                                    <li>
                                        <a href='#' onClick={()=>createOrder(row.id, row.gesami_netto, 19, row.gesami_brutto)} className="dropdown-item">
                                            {t('Create Order')}
                                        </a>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                </>
                            ) : <></>}

                            {Cookies.get('permissions').indexOf("quotation-update") !== -1 ? (
                                <>
                                    <li>
                                        <NavLink to={'/sales-distribution/quations/' + row.id + '/edit'} className="dropdown-item">
                                            {t('Edit')}
                                        </NavLink>
                                    </li>
                                    <li><hr className="dropdown-divider" /></li>
                                </>
                            ) : <></>}
                            {Cookies.get('permissions').indexOf("quotation-delete") !== -1 ? (
                                <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'quation', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                            ) : <></>}
                        </ul>
                    </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("quation?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh]);

    useEffect(()=>{
        if(searchKey.length > 0){
            callFetch("quatation/search/"+searchKey, "GET", []).then((res) =>{
                setTableData(res.data);
            })
        }else{
            setRefresh(refresh + 1)
        }
    },[searchKey])

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return submitSuccess ? <Navigate to={'/sales-distribution/order/' + orderId + '/edit'} /> :
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                                return (
                                    <li key={page.label} className={className+' '+(parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                        <button
                                            className="page-link"
                                            onClick={page.label != '...' ? handlePageNumber : ''}
                                            value={page.label}
                                            disabled={page.label == '...' ? true : false}
                                        >
                                            {page.label}
                                        </button>
                                    </li>
                                );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        ;
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
        subHeader
        subHeaderComponent={<input type="text" placeholder={ t("Search...") } className=' form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} />}
        
    />;
}

export default IndexTable;
