import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'; 
import flatpickr from "flatpickr"; 

import Cookies from 'js-cookie';

import PDF from "./PDF";
import SignaturePad from 'react-signature-pad-wrapper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import callFetch from "helpers/callFetch";

const DCabnahmeSign = (props,{project_id}) => {
    const { t } = useTranslation(); 
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showPadClient, setShowPadClient] = useState(true);
    const [showPad, setShowPad] = useState(true);
    const [submitSuccess, setSubmitSuccess] = useState(false); 
    const signaturePadRef = useRef(null);
    const signaturePadRefClient = useRef(null); 
    const [data, setData] = useState([]);
    const [playAnimation, setPlayAnimation] = useState(true);
    const {
        reset, resetField, register, handleSubmit, setError, setValue, getValues,
        formState: { errors },
      } = useForm();
    useEffect(() => {
        flatpickr(".flat-pickr");
        callFetch("dc_abnahmeprotokoll/"+props.project_id+"/edit","GET",[]).then((res)=>{
            setData(res.data)
            if(res.data?.sign?.officer_sign !== null || res.data?.sign?.client_sign !== null){
                signaturePadRef.current.fromDataURL(res.data?.sign?.officer_sign);
                signaturePadRefClient.current.fromDataURL(res.data?.sign?.client_sign);
            }
        })
        
    }, [refresh]);

    useEffect(()=>{
        
    },[refresh])



    useEffect(() => {
        console.log('test');
    });

    const hideSign = () => {
        setShowPad(true);
    }
    
    const hideSignClient = () => {
        setShowPadClient(true);
    } 

    useEffect(() => { // useEffect hook
        if(playAnimation){
            //document.body.classList.add('loading');
        } 
          setTimeout(() => { // simulate a delay
             setPlayAnimation(false)
            //document.body.classList.remove('loading');
           }, 10000);
    }, [refresh]);
   
 
    const onSubmit = (formData) => { 
        setSaving(true);
        if(signaturePadRef.current.isEmpty() && signaturePadRefClient.current.isEmpty()){
            alert('Sign please');
            return false;
        }
        formData.sign = signaturePadRef.current.toDataURL('image/png');
        formData.client_sign = signaturePadRefClient.current.toDataURL('image/png')
        formData.id = data?.dcabname?.id;
        callFetch("dcsign", "POST", formData, setError).then((res) => { 
            setRefresh(refresh + 1);
            setPlayAnimation(true); 
            setSaving(false);
        });  
    }
    return submitSuccess ? <Navigate to='/sales-distribution/orders' /> :
    <> 
        
        <div className="row">
            <div className="col-lg-12 mt-lg-0" style={{position: 'relative'}}>
                {/* {playAnimation ? <div className="loader-pdf"><p>Loading...</p></div> : ''} */}
                
               

                <div className={playAnimation ? 'card mb-4 loading' : "card mb-4"}>
                    <div className="card-body">
                        <PDF playAnimation={playAnimation} data={data} refresh={refresh+1}></PDF> 
                        {Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-update") !== -1 ? (
                        <div className="row">
                            <div className="col-sm-6">
                                {!showPad ? <div>
                                    <div className="sign-pad"> 
                                        <img style={{width: '100%'}} src={data && data.order ? process.env.REACT_APP_STORAGE_URL+'signs/'+data.order.sign : ''} alt=""/>                                    
                                        <p style={{borderTop: '1px solid #000', textAlign: 'center'}}>Unterschrift Bauherr/Auftraggeber</p> 
                                    </div> <button type="button" className="btn btn-outline-dark"  onClick={() => { hideSign(); }}>
                                        <i className="fa fa-eraser"></i>
                                    </button>
                                </div>: ''}
                                {showPad ?<div><div className="sign-pad">
                                     <SignaturePad options={{minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)'}} ref={signaturePadRef}/>
                                    <p style={{borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px'}}>Unterschrift Bauherr/Auftraggeber</p>  
                                </div>
                                <button type="button" className="btn btn-outline-dark"  onClick={() => { signaturePadRef.current.clear(); }}>
                                     <i className="fa fa-eraser"></i>
                                </button>   </div>  : ''}
                                
                            </div>
                            <div className="col-sm-6"> 
                                {!showPadClient ? <div><div className="sign-pad">
                                    <i className="fa fa-pencil" onClick={() => hideSignClient()} style={{position: 'relative', top: '140px'}}></i>
                                    <img style={{width: '100%'}} src={data && data.order ? process.env.REACT_APP_STORAGE_URL+'signs/'+data.order.client_sign : ''} alt=""/>                                    
                                    <p style={{borderTop: '1px solid #000', textAlign: 'center'}}>Unterschift Installateur</p> 
                                </div> <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                    <i className="fa fa-eraser"></i>
                                </button></div> : ' '}
                                {showPadClient ? <div><div className="sign-pad-new">
                                    <SignaturePad options={{minWidth: 1, maxWidth: 1, penColor: 'rgb(0, 0, 0)'}} ref={signaturePadRefClient}/>
                                    <p style={{borderTop: '1px solid #000', textAlign: 'center', marginTop: '18px'}}>Unterschift Installateur</p> 
                                </div>
                                <button type="button" className="btn btn-outline-dark" onClick={() => { signaturePadRefClient.current.clear(); }}>
                                    <i className="fa fa-eraser"></i>
                                </button>  </div> : ''}
                            </div> 
                        </div>
                        ) : <></>}
                    </div> 
                </div>
                {Cookies.get('permissions').indexOf("dc_bnahmeprotokoll-update") !== -1 ? (
                    <form onSubmit={handleSubmit(onSubmit)}> 
                        <div className="row"> 
                            <div className="col-sm-4">
                                {!saving && (
                                    <button type="submit" id="saveSign" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                    
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}   
                            </div> 
                        </div>
                    </form>
                ) : <></>}
            </div>
            {/* <div className="col-lg-3">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Order Nr.')}</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {data && data.order ? data.order.id : '---'}                                               
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div>
                                        <div className="icon icon-shape bg-gradient-dark text-center border-radius-md">
                                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="ms-3">
                                        <div className="numbers">
                                            <p className="text-sm mb-0 text-capitalize font-weight-bold">{t('Creator')}</p>
                                            <h5 className="font-weight-bolder mb-0">
                                                {data && data.order && data.order.user ? data.order.user.name : '-'}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-3">
                    <div className="card-header pb-0">
                        <h6>{t('Email')}</h6>
                    </div>
                    <div className="card-body">
                        
                    </div>
                </div>
                <Timeline/>
            </div> */}
        </div>
        <ToastContainer />
        </>
    ;
}

export default DCabnahmeSign