import { React, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { NavLink, useParams } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import { NumericFormat } from 'react-number-format';
import dateFormat from "dateformat";
import SoftTypography from "components/SoftTypography";
import { Navigate } from "react-router-dom";
import SoftBadgeDot from "components/SoftBadgeDot";

const DachmontageIndexTable = () => {
    const { t } = useTranslation();
    let params = useParams();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey,setSearchKey] = useState("")
    const [searchData,setSearchData] = useState([])
    const [filterData,setFilterData] = useState([])

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [orderId, setOrderId] = useState(0);

    const createOrder = (quation_id, gesami_netto, tax, gesami_brutto) => {
        setSaving(true);
        callFetch("order", "POST", {
            quation_id: quation_id,
            currency: 'EUR',
            netto: gesami_netto,
            tax: tax,
            brutto: gesami_brutto,
        }, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setOrderId(res.data.id);
            setSubmitSuccess(true);
        });
    };

    const tableHeadings = [
        {
            name: t('Project Nr.'),
            sortable: true,
            selector: row => <NavLink to={'/project-management/project/edit/' + row.id}> {row.id}</NavLink>,
        },

        {
            name: t('AC Technician'),
            sortable: true,
            cell: row => (
                <>
                {/*row?.assignorderprocessings?.employee?.name*/}
                {row?.assignorderprocessings.map((assignorderprocessing) => {
                    return (
                        <>{assignorderprocessing?.dachmontag_elektromontage == 'Elektromontage' ? assignorderprocessing?.employee?.name : ''}</>
                    );
                })}
                </>
            ),
        },
        {
            name: t('Date of Event'),
            sortable: true,
            selector: row => dateFormat(row.date, "dd.mm.yyyy"),
        },
        /*
        {
            name: t('Status'),
            sortable: true,
            cell: row => (
                <>
                    <span style={{height: '0.375rem', width: '0.375rem', backgroundColor: row.status == 'Offen' ?
                                '#21d4fd' : row.status == 'Erledigt' ? 
                                '#9320bb' : 'dark', borderRadius: '50%', display: 'inline-block'}}></span>
                    <SoftTypography style={{position: 'relative', top: '1px', left: '3px', fontSize: '0.75rem', display: 'inline'}}>{row.status}</SoftTypography>
                </>
                
            )
        },
       
        {
            name: t('Actions'),
            sortable: true,
            cell: row => (
                <>
                    {Cookies.get('permissions').indexOf("quotation-update") !== -1 ||
                    Cookies.get('permissions').indexOf("order-create") !== -1 ||
                    Cookies.get('permissions').indexOf("quotation-delete") !== -1 ? (
                        <div className="text-center dropstart">
                        <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-ellipsis-v text-xs"></i>
                        </a>
                        <ul className="dropdown-menu">
                            {Cookies.get('permissions').indexOf("quotation-delete") !== -1 ? (
                                <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'filed_service', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                            ) : <></>}
                        </ul>
                    </div>
                    ) : <></>}
                </>
            )
        }
        */
    ];

    useEffect(() => {
        callFetch("filed_service?page=" + pageNumber+'&status='+params.slug+'&dc_ac=ac', "GET", []).then((res) => {
            setTableData(res.data);
        });
    }, [pageNumber, refresh, params.slug]);

    useEffect(()=>{
        if(searchKey.length > 0){
            callFetch("dc/search/"+searchKey, "GET", []).then((res) =>{
                setFilterData(res.data.data)
                setTableData(res.data);
            })
        }else{
            setRefresh(refresh + 1)
        }
    },[searchKey])

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return submitSuccess ? <Navigate to={'/sales-distribution/order/' + orderId + '/edit'} /> :
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        ;
    };
  return<DataTable
  columns={tableHeadings}
  data={tabledata?.data}
  noDataComponent={t('There are no records to display')}
  pagination
  highlightOnHover
  paginationComponentOptions={{ noRowsPerPage: true }}
  paginationServer
  paginationTotalRows={tabledata?.total}
  onChangePage={handlePageChange}
  paginationComponent={BootyPagination}
  subHeader
  subHeaderComponent={<input type="text" placeholder={ t("Search...") } className=' form-control w-sm-50 w-md-25 w-lg-15' value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} />}
/>;
}

export default DachmontageIndexTable