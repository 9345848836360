import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
//import RestoreIcon from '@material-ui/icons/Restore';
//import FavoriteIcon from '@material-ui/icons/Favorite';
//import LocationOnIcon from '@material-ui/icons/LocationOn';
import './style.css';

const styles = {
  root: {
    width: '100%',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#005498',
    color: '#000',
    zIndex: 3,
  },
  button: {
    color: '#000',
  },
};

class MobileBottomNavigation extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <BottomNavigation
        value={value}
        onChange={this.handleChange}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction 
          label="" 
          icon={<i class="fa-solid fa-bars"></i>} 
          className={classes.button}
          onClick={()=>{
            document.getElementById('iconNavbarSidenav').click(); //topNav button id
          }}
        />

        <BottomNavigationAction
          label=""
          icon={<i className='fa-solid fa-envelope'></i>}
          className={classes.button}
          onClick={()=>{
            document.getElementById('chat-link').click(); //topNav button id
          }}
        />

        <BottomNavigationAction
          label=""
          icon={<i className='fa fa-bell'></i>}
          className={classes.button}
          onClick={()=>{
            console.log('Notification')
          }}
          />
        <BottomNavigationAction
          label=""
          icon={<i class="fa-solid fa-user"></i>}
          className={classes.button}
          onClick={()=>{
            console.log('Profile')
          }}
        />
      </BottomNavigation>
    );
  }
}

MobileBottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileBottomNavigation);
//export default MobileBottomNavigation;