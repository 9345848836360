import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function CorrectionRequest(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        setValue('project_id', params.id);
        //setValue('related', props.related);
        setValue('status', 'Revision');
    }, [props]);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("correction-request", "POST", formData, setError).then((res) => {
            setValue('description', '');
            setSaving(false);
            props.setRefresh(props.refresh+1);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return(
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register("project_id")} />
                {/*<input type="hidden" {...register("related")} />*/}
                <input type="hidden" {...register("status")} />

                <div className="modal fade" id="correctionRequest" tabindex="-1" role="dialog" aria-labelledby="correctionRequest" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content" >
                            <div className="modal-header">
                                <h5 className="modal-title" id="correctionRequestLabel">{t('Correction Request')}</h5>
                                <button type="button" className="btn-close text-dark" id="correctionRequestClose" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{minHeight: '400px', maxHeight: '400px'}} data-simplebar>

                                    <div className="form-group">
                                    <label>{t('Correction For')} *</label>
                                    <select {...register("related")} className="form-control">
                                        <option value={'Appointment'}>{t('Appointment')}</option>
                                        <option value={'Quotation'}>{t('Quotation')}</option>
                                        <option value={'Order'}>{t('Order')}</option>
                                    </select>
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>

                                <div className="form-group">
                                    <label>{t('Description')} *</label>
                                    <textarea {...register("description")} className="form-control" style={{fontSize: '0.75rem', height: '240px'}}></textarea>
                                    <div className="invalid-feedback">{errors.description && errors.description.message}</div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary mb-0" data-bs-dismiss="modal" aria-label="Close">
                                        {t('Send')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled mb-0" disabled data-bs-dismiss="modal" aria-label="Close">
                                        {t('Sending ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default CorrectionRequest;
