/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftAlert from "components/SoftAlert";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

function ChangePassword() {
    let params = useParams();
    const { t } = useTranslation();  
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false); 
    const [refresh, setRefresh] = useState(0);
    const [passError, setPassError] = useState(false); 
    const [passErrorNot, setPassErrorNot] = useState(false); 
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
} = useForm();

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];


  const onSubmit = (formData) => {
    setSaving(true); 
    callFetch("update-password", "POST", formData, setError).then((res) => {
        setSaving(false);
        setValue('current_password', ''); 
        setValue('new_password', ''); 
        setValue('new_password_confirmation', '');  
        if(res.message == 'The new password confirmation does not match.'){
          setPassErrorNot(true);
          setPassError(false);
        }else{
          if(res.message == 'error'){
            console.log('cpw');
            setSubmitSuccess(false);
            setPassError(true);
            setPassErrorNot(false);
          }else{
            setPassError(false);
            setPassErrorNot(false);
            setSubmitSuccess(true);
          } 
        }

         
        // console.log(res.errors.current_password[0]);
        // console.log(res.errors.new_password[0]);
        // if(Array.isArray(res.errors) && res.errors.length){
        //   if(res.message == 'error'){
        //     setSubmitSuccess(false);
        //     setPassError(true);
        //   }else{
        //     setPassError(false);
        //     setSubmitSuccess(true);
        //   } 
        // }else{
          
        // } 
    });
};

 

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('Change Password')}</SoftTypography> 
        {submitSuccess ? <SoftAlert color="success" mt={3} dismissible>Password Changed Successfully</SoftAlert> : ' '} 
        {passError ? <SoftAlert color="error" mt={3}>Current Password Doesn't match!</SoftAlert> : ' '} 
        {passErrorNot ? <SoftAlert color="error" mt={3}>New password and confirm password does not match</SoftAlert> : ' '} 
      </SoftBox>
      <SoftBox  pb={3} px={3}>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <input className="form-control mt-2"
              {...register('current_password', {
                required: true,
            })}
              label="current password"
              placeholder={t('Current Password', {
                required: true,
            })}
              type="password" required
            />
          </Grid>
          <Grid item xs={12}>
            <input className="form-control mt-2"
              {...register('new_password',{
                required: true,
            })}
              label="new password"
              placeholder={t('New Password')}
              type="password" required
            />
          </Grid>
          <Grid item xs={12}>
            <input className="form-control mt-2"
              {...register('new_password_confirmation')}
              label="confirm new password"
              placeholder={t('Confirm Password')}
              type="password" required
            />
          </Grid>
        </Grid> 
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox ml="auto">
          <div className="row g-3 mt-3">                     
                                <div className="col-12 my-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
          </SoftBox>
        </SoftBox>
        </form>
      </SoftBox>
    </Card>
  );
}

export default ChangePassword;
