import { useMemo, useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import callFetch from "../../../helpers/callFetch";
import { useForm } from "react-hook-form";

function ProjectStatusCards(props) {
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(0);
    //const [users, setUsers] = useState([]);
    const [filterData, setFilterData] = useState({
        verkaufer: 0,
        created_at: 'All',
    });

    useEffect(() => {
        callFetch("statistics/project-status-card", "POST", filterData, setError).then((res) => {
            setData(res.data);
            //setUsers(res.users);
            if (!res.ok) return;
        });
    }, [refresh]);

    useEffect(() => {
        if(!props?.filterData?.created_at) return;
        
        if(props?.filterData?.created_at != filterData?.created_at){
            var data = filterData;
            data.created_at =  props?.filterData?.created_at;
            setFilterData(data);
            setRefresh(refresh+1)
        }
    }, [props?.filterData?.created_at]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniStatisticsCard
                        title={{ text: "Angebote", fontWeight: "medium" }}
                        count={data?.total_angebote ? data?.total_angebote : '--'}
                        icon={{ color: "dark", component: "watch" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniStatisticsCard
                        title={{ text: "Auftrage", fontWeight: "medium"}}
                        count={data?.total_auftrage ? data?.total_auftrage : '--'}
                        icon={{ color: "dark", component: "image" }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                    <MiniStatisticsCard
                        title={{ text: "Offene Projekte", fontWeight: "medium" }}
                        count={data?.total_open_projects ? data?.total_open_projects : '--'}
                        icon={{ color: "dark", component: "account_circle" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MiniStatisticsCard
                        title={{ text: "Storno", fontWeight: "medium" }}
                        count={data?.total_storno_projects ? data?.total_storno_projects : '--'}
                        icon={{ color: "dark", component: "public" }}
                    />
                </Grid>
            </Grid>          
        </>
        )
    }
    
export default ProjectStatusCards;