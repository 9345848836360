import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';

function DateForm(props) {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [totalEmails, setTotalEmails] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        callFetch("projects/" + props.projectid + "/edit", "GET", []).then((res) => {
            setValue('date', res.data.desired_assembly);         
        });
    }, []);

    const onSubmit = (formData) => {
        setSaving(true);
        callFetch("save-desired-assembly", "POST", formData, setError).then((res) => {
            console.log('date-saved');
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

 

    return(
        <>
            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <input type="hidden" {...register('id')} value={props.projectid}/>
                <div className="card mb-3">
                    <div className="card-header pb-0">
                        <h6>{t('Wunschtermin Montage')}</h6>
                    </div>
                    <div className="card-body">
                        <div><label>Datum *</label>
                            <input type="date" class="form-control mb-2 flatpickr" placeholder="z.B. 16-04-2022" {...register('date')} required=""/>
                            <div class="invalid-feedback"> </div>
                        </div>
                        <div className="row g-3 mt-2">                     
                            <div className="col-12">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default DateForm;
