import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import callFetch from 'helpers/callFetch';
import EditPlan from "../EditPlan";

const OverviewListTable = () => {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [planning, setPlanning] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [searchKey,setSearchKey] = useState("")

    const tableHeadings = [
        {
            name: t('Project Nr.'),
            selector: row => <NavLink to={'/project-management/project/edit/' + row?.project_id} className="text-primary">{row?.project_id}</NavLink>,
            sortable: true,
        },

        {
            name: t('Technician Name'),
            sortable: true,
            cell: row => row?.employee?.name,
        },     
        {
            name: t('Abteilung'),
            sortable: true,
            selector: row => row?.employee?.roles[0]?.name,
        },
        {
            name: t('Planung'),
            sortable: true,
            selector: row => row?.planning,
        },
        {
            name: t('Stadium'),
            sortable: true,
            selector: row => row?.stadium,
        },
        {
            name: t('Action'),
            sortable: true,
            selector: row => (
                <>
                {row?.action != 'Reklamation' ?
                    <a
                        className="btn btn-primary btn-sm m-0"
                        onClick={()=>{
                            var data = {
                                id: row?.id,
                                project_nr: row?.project_id,
                                title: row?.title,
                                action: row?.action,
                            };
                            // setPlanning(data);
                            //console.log(data)

                            if(row?.action == "create_plan"){
                                document.getElementById('triggercardNewPlanBtn'+row?.project_id).click();
                            }else if(row?.action == "edit_plan"){
                                setPlanning(data);
                                document.getElementById('triggerEditPlanBtn').click();
                            }
                        }}
                    >{t('Planning')}</a>
                    : ''}
                </>
            ),
        }
    ];


    useEffect(() => {
        callFetch("filed_service/overViewList?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
            
        });
        
    }, [pageNumber, refresh]);

useEffect(()=>{
    /*
    if(searchKey.length > 0){
        callFetch('order/serach/'+searchKey, "GET", []).then((res)=>{
            setTableData(res.data)
        })        
    }else{
        setRefresh(refresh + 1)
    }
    */
},[searchKey])
    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata?.from} {t('to')} {tabledata?.to} {t('of')} {tabledata?.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {tabledata?.links.map((page) => {
                            //const className = (parseInt(page.label) === currentPage ? "page-item active" : "page-item");
                            const className = (page.active === true ? "page-item active" : "page-item");
                                return (
                                    <li key={page.label} className={className+' '+(parseInt(page.label) || page.label == '...' ? '' : 'd-none')}>
                                        <button
                                            className="page-link"
                                            onClick={page.label != '...' ? handlePageNumber : ''}
                                            value={page.label}
                                            disabled={page.label == '...' ? true : false}
                                        >
                                            {page.label}
                                        </button>
                                    </li>
                                );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return(
        <>
            <DataTable
                columns={tableHeadings}
                data={tabledata?.data}
                noDataComponent={t('There are no records to display')}
                pagination
                highlightOnHover
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationServer
                paginationTotalRows={tabledata?.total}
                onChangePage={handlePageChange}
                paginationComponent={BootyPagination}
                subHeader
                subHeaderComponent={<input className=' form-control w-sm-50 w-md-25 w-lg-15' type="text" value={searchKey} onChange={(e)=>setSearchKey(e.target.value)} />}
            />


            <EditPlan data={planning} setRefresh={setRefresh} />
        </>
    );
    
}

export default OverviewListTable
