// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftAlert from "components/SoftAlert";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

function ChangePassword(props) {
    let params = useParams();
    const { t } = useTranslation();  
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false); 
    const [passError, setPassError] = useState(false); 
    const [refresh, setRefresh] = useState(0);
    const [passErrorNot, setPassErrorNot] = useState(false); 

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
} = useForm();

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  useEffect(() => {
    setValue('id',props.id)
  });

  const onSubmit = (formData) => {
    setSaving(true); 
    console.log(props.id);
    callFetch("update-employee-password", "POST", formData, setError).then((res) => {
        setSaving(false);
        setValue('current_password', ''); 
        setValue('new_password', ''); 
        setValue('new_password_confirmation', '');
        if(res.message == 'The new password confirmation does not match.'){
          setPassErrorNot(true);
          setPassError(false);
        }else{
          if(res.message == 'error'){
            console.log('cpw');
            setSubmitSuccess(false);
            setPassError(true);
            setPassErrorNot(false);
          }else{
            setPassError(false);
            setPassErrorNot(false);
            setSubmitSuccess(true);
          } 
        }  
    });
};

 

  return (
    <Card id="change-password">
      <SoftBox p={3}>
        <SoftTypography variant="h5">{t('Change Password')}</SoftTypography> 
        {submitSuccess ? <SoftAlert color="success" mt={3} dismissible>{t('Password Changed Successfully')}</SoftAlert> : ' '} 
        {passError ? <SoftAlert color="error" mt={3} dismissible>{t("Current Password Doesn't match!")}</SoftAlert> : ' '} 
        {passErrorNot ? <SoftAlert color="error" mt={3}>{t('New password and confirm password does not match')}</SoftAlert> : ' '} 
      </SoftBox>
      <SoftBox  pb={3} px={3}>
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Grid container spacing={3}>
          {/* <Grid item xs={12} style={{paddingTop: '15px'}}>
            <input className="form-control" {...register("current_password", {
                                                        required: true,
                                                    })}
                                                    required 
              label="current password"
              placeholder="Current Password"
              type="password" 
            />
          </Grid> */}
          <Grid item xs={12} style={{paddingTop: '15px'}}>
            <input className="form-control" {...register("new_password", {
                                                        required: true,
                                                    })}
                                                    required 
              label="new password"
              placeholder={t("New Password")}
              type="password" 
            />
          </Grid>
          <Grid item xs={12} style={{paddingTop: '15px'}}>
            <input className="form-control" {...register("new_password_confirmation", {
                                                        required: true,
                                                    })}
                                                    required  
              label="confirm new password"
              placeholder={t("Confirm Password")}
              type="password" 
            />
          </Grid>
        </Grid> 
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <SoftBox ml="auto">
          <div className="row" style={{paddingTop: '15px'}}>                     
                                <div className="col-12">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving ...')}
                                        </button>
                                    )}
                                </div>
                            </div>
          </SoftBox>
        </SoftBox>
        </form>
      </SoftBox>
    </Card>
  );
}

export default ChangePassword;
