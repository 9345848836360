import React, { useState, useRef, useEffect } from "react";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import Cookies from "js-cookie";
import callFetch from "helpers/callFetch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CardCarousel from "./component/CardCarousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./component/slider.css"
import { AnimatePresence, motion,LazyMotion, domAnimation, m } from "framer-motion";
import NewPlan from "./NewPlan";

const CardData = () => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);
  const [fieldService,setFieldService] = useState({})
  const [cardData,setCardData] = useState([])
  const [isVisible,setIsVisible] = useState(0)

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 884,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const chooseMessage = (data) =>{
    setFieldService(data)
  }
  const dashboardRefresh=({msg})=>{
    alert(msg)
    setRefresh(1)
  }

  useEffect(() => {
    callFetch("projects/calanderdata", "GET", []).then((res) => {
      setCardData(res.cardData);
      //If create plan for cartData project then cartData remove
      res.data?.map((firstObj) => {
        res.cardData?.map((compareObj, i) => {
          if (firstObj.id === compareObj.project_nr) {
               //res.cardData.splice(0, 1);
          }
        })
      })
    });
  }, [refresh,isVisible]);
  return (
    <>
        <AnimatePresence initial={true}>
            <Slider {...settings}>
              {cardData.map((item, index) => (
                <motion.div
                  initial={{ opacity:0 }}
                  animate={{ opacity: isVisible && isVisible === item.project_nr ? 0:1 }}
                  exit={{ opacity:0 }}
                >
                  
                  <Grid key={index} width="100%" xs={12} sm={6} md={6} lg={4} >
                    <SoftBox width="100%" p={1} mb={5}>
                      <CardCarousel
                        icon={item.icon}
                        title={t('Project Nr')}
                        data={item}
                        project_nr={item.project_nr}
                        chooseMessage={chooseMessage}
                      />
                    </SoftBox>
                  
                  </Grid>
                </motion.div>                 
              ))}              
            </Slider>
        </AnimatePresence>

        <NewPlan project_nr={fieldService.project_nr} title={fieldService.title} setRefresh={setRefresh} />
    </>
  );
};

export default CardData;
